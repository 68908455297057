@import 'foundation';

$heigthImgTileRatio: 43.41142020497804vw;
$promoPlp: #07852B;
.image-container {
    .custom-plp-dynamic-content {
        width: 100%;
        position: absolute;
        bottom: 0;
        font-size: rem-calc(10);
        @include breakpoint(small down) {
            font-size: rem-calc(9);
        }
        color: $white;
        background-color: #8f9dcc;
        text-align: center;
        .custom-element-root {
            width: inherit;
            & * {
                padding: rem-calc(4.5) 0;
                margin: 0;
                font-size: inherit;
                line-height: 1;
                font-weight: bold;
            }
        }
    }
}
.product-tile-cell {
    &.medium-1-of-4 {
        @include xy-cell($size: 50%, $breakpoint: small);
        @include breakpoint(medium up) {
            @include xy-cell($size: 25%, $breakpoint: large);
        }
    }
    &.medium-2-of-4 {
        @include xy-cell($size: 100%, $breakpoint: small);
        @include breakpoint(medium up) {
            @include xy-cell($size: 50%, $breakpoint: large);
            /*
            .special-product {
                width: 75%;
            }
            */
        }
    }
    .product {
        width: 100%;
    }
    /*
    .icon_category_image {
        height: 1rem;
        width: 1rem;
    }
    */
    .pdp-link {
        line-height: rem-calc(20);
        max-height: rem-calc(40);
        overflow: hidden;
        font-weight: 400;
        @include breakpoint(medium down) {
            font-size: rem-calc(13);
        }
        /*
        .new-message {
            font-size: rem-calc(9);
        }
        */
        .link {
            font-size: rem-calc(14);
        }
    }
    .image-container {
        position: relative;
        padding-top: 133.3%; 
        overflow: hidden;

        .tile-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 98%;
            object-fit: cover;
        }
        /*
        .product-tile__strip {
            position: absolute;
            right: calc(-150% + #{rem-calc(24)});
            top: 50%;
            text-align: center;
            width: 300%;
            pointer-events: none;

            .inner-transformation {
                transform: rotate(-90deg);
                white-space: nowrap;
            }

            span {
                font-size: rem-calc(20);
                line-height: 2;
                margin-right: rem-calc(12);
                text-transform: uppercase;
                font-weight: $global-weight-dark;
            }
        }
        */
        .comingSoonBox &:not(.comingSoonBoxPLP) {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            // background: rgba($more-light-gray, 0.8);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: rem-calc(16);

            .force-hover {
                text-decoration: underline;
                font-weight: $global-weight-normal;
                font-size: rem-calc(12);
            }

            &:not(.hidden) {
                display: flex;
            }
        }
        .comingSoonBox {
            &:not(.hidden) {
                display: flex;
            }
        }
    }
    .product-tile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        margin-bottom: rem-calc(8);
        @include breakpoint(large up) {
            position: relative;
        }
        &--big {
            text-align: center;
            justify-content: center;
            .image-container {
                padding-top: 66.666666%; // 2/3 aspect ratio

                .tile-image {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    height: 100%;
                    width: unset;
                }
            }
        }
        /*
        .customize {
            font-weight: $global-weight-normal;
            &__icon {
                display: inline-block;
                vertical-align: bottom;
                width: rem-calc(18);
            }
            .pipe {
                margin: 0;
                padding: rem-calc(0 4);
            }
        }
        */
    }
}
/*
.plp-tag {
    height: rem-calc(21);
    span {
        font-size: rem-calc(11);
        font-weight: 600;
    }
}
*/
.margin-bottom-15 {
    margin-bottom: rem-calc(15);
}

.productsContainer {
    @include breakpoint(medium down) {
        margin: 0 !important;
    }
    .productItem {
        // .tile-image {
        //    height: 100%;
        // }
        &-normal {
            @include breakpoint(medium down) {
                img.tile-image {
                    width: 100%;
                    height: 100%; // the aspect ratio is set on the parent div
                }
                width: calc(50% - 4px);
                &:not(.horizontal) {
                    &:nth-child(5n + 1),
                    &:nth-child(5n + 3) {
                        margin-right: rem-calc(4);
                        margin-left: 0;
                    }
                    &:nth-child(5n + 2),
                    &:nth-child(5n + 4) {
                        margin-left: rem-calc(4);
                        margin-right: 0;
                    }
                    &:nth-child(5n) {
                        width: 100%;
                        margin: 0;
                    }
                }
                &.horizontal {
                    &:nth-child(5n + 2),
                    &:nth-child(5n + 4) {
                        margin-right: rem-calc(4);
                        margin-left: 0;
                    }
                    &:nth-child(5n + 1),
                    &:nth-child(5n + 3),
                    &:nth-child(5n + 5) {
                        margin-left: rem-calc(4);
                        margin-right: 0;
                    }
                }
            }
            .product {
                @include flexGenerator(column);
            }
            .product-tile {
                @include flexGenerator(column, flex-start);
            }
            .tile-video {
                // MEC-12628
                display: flex;
                video {
                    object-fit: cover;
                }
            }
            @include breakpoint(large up) {
                width: calc(100% / 3 - 8px);
                /*
                img.tile-image,
                .tile-video {
                    height: $heigthImgTileRatio;
                }
                */
            }
        }
        &-horizontal {
            .product {
                @include flexGenerator(column);
            }
            .product-tile {
                @include flexGenerator(column, flex-start);
            }
            img.tile-image {
                width: unset;
                height: 100%;
            }
            @include breakpoint(medium down) {
                width: 100%;
                margin: 0;
                img.tile-image {
                    width: 100%;
                    height: auto;
                }
                
            }
            @include breakpoint(large up) {
                width: calc(100% / 3 * 2 - 8px);
                /*
                img.tile-image,
                .tile-video {
                    height: $heigthImgTileRatio;
                }
                */
                .image-container {
                    overflow: hidden;
                    margin: 0 auto;
                    width: fit-content;
                    height: calc(
                        ((((100vw - 86px) * (1 / 3))) * 7 / 5) - (8px * 7 / 5)
                    );
                }
            }
        }
        &.productItem-plpOnly {
            .product {
                .productDescription {
                    .productDescription-priceRow {
                        @include breakpoint(medium down) {
                            margin-bottom: rem-calc(8);
                        }
                        .price-container {
                            @include breakpoint(medium down) {
                                margin-bottom: 0;
                            }
                            .price {
                                .strike-through {
                                    &,
                                    &:hover {
                                        text-decoration: line-through;
                                        text-decoration-color: $light-gray;
                                    }
                                    .value {
                                        font-size: rem-calc(14);
                                        line-height: rem-calc(20);
                                        margin: 0 rem-calc(8);
                                        color: $light-gray;

                                        @include breakpoint(small down) {
                                            margin: 0 rem-calc(2);
                                            font-size: rem-calc(12);
                                        }
                                    }
                                }
                                .strike-through ~ .sales {
                                    :not(.js-discount-msg) {
                                        color: black;
                                    }
                                }
                                .span-price-row-plp {
                                    display: inline-block;

                                    .sales {
                                        order: 2;
                                        &.first-span {
                                            order: 0;
                                        }
                                    }
                                    .strike-through {
                                        order: 1;
                                    }
                                }
                                .percentage {
                                    @include breakpoint(medium down) {
                                        width: 100%;
                                        margin-top: rem-calc(8);
                                    }
                                }
                                .js-discount-msg {
                                    font-size: rem-calc(14);
                                    font-weight: 600;
                                    line-height: rem-calc(20);

                                    @include breakpoint(small down) {
                                        font-size: rem-calc(12);
                                    }
                                }
                            }
                        }
                    }
                    .product-tile__promo-message {
                        margin-top: rem-calc(-8);
                        .promo-message.hidden {
                            display: none !important;
                        }
                        .js-promo-container {
                            margin-top: rem-calc(8);
                        }
                        & + .tile-colors:not(.hide) {
                            @include breakpoint(medium down) {
                                margin-top: rem-calc(8);
                            }
                        }
                    }
                    .productDescription-name {
                        @include breakpoint(medium down) {
                            margin-bottom: rem-calc(8);
                            .pdp-link {
                                .link {
                                    line-height: rem-calc(20);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(large up) {
    .product-tile:hover {
        .image-container .slick-arrow {
            visibility: visible;
            z-index: 1;
            &:before {
                visibility: visible;
            }
        }
    }
}
.image-container {
    .slick-arrow {
        visibility: hidden;
        @include fixWidth(40px);
        @include fixHeight(60px);
        &:before {
            position: absolute;
            visibility: hidden;
        }
        &.slick-prev {
            left: 0;
            &:before {
                content: url('../images/ico-arrow-left-new.svg');
                left: rem-calc(20);
                bottom: rem-calc(16);
            }
        }
        &.slick-next {
            right: 0;
            &:before {
                content: url('../images/ico-arrow-left-new.svg');
                right: rem-calc(20);
                bottom: rem-calc(19);
                transform: rotate(180deg);
            }
        }
    }
    .newProdLabel {
        padding: rem-calc(2 6);
        position: absolute;
        top: rem-calc(17);
        left: rem-calc(16);
        font-size: rem-calc(12);
        text-transform: uppercase;
        font-weight: 600;
        line-height: rem-calc(14);
        backdrop-filter: blur(5px);
        background-color: $white;
        height: rem-calc(16);
        border-radius: rem-calc(20);
        font-family: $secondary-font;

        &.promo-label-lower {
            margin-top: rem-calc(13);
            z-index: 1;
        }

        @include breakpoint(medium down) {
            font-size: rem-calc(10);
            padding: rem-calc(2 4);
            top: rem-calc(16);
            line-height: rem-calc(12);
            height: rem-calc(15);
        }
        .info-icon {
            display: none;
        }
        .js-modalContent {
            display: none;
        }
    }
}

.productDescription {
    @include flexGenerator(column);
    flex-grow: 0;
    padding: rem-calc(16 16 0);
    margin-bottom: rem-calc(40);
    @include breakpoint(large up) {
        padding: rem-calc(16 20 0 16);
        margin-bottom: rem-calc(56);
    }
    .promotion-info-plp {
        font-size: rem-calc(12);
        font-weight: 600;
        @include flexGenerator(row, flex-start, center);
        line-height: rem-calc(18);
        margin-bottom: 0;
        color: $promo;
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        /*
        .promo-info-icon {
            margin-left: rem-calc(5);
        }
        */
    }
    &-name {
        @include flexGenerator(row, space-between, flex-start);
        flex-grow: 0;
        line-height: rem-calc(20);
        margin-bottom: rem-calc(8);
        @include breakpoint(large up) {
            margin-bottom: rem-calc(8);
            .product-tile__name {
                max-width: 100%;
                &.inPlp {
                    max-width: calc(100% - 16px);
                }
            }
        }
        .pdp-link {
            line-height: rem-calc(20);
            margin-right: rem-calc(20);
            @include breakpoint(large up) {
                margin-right: rem-calc(4);
            }
            .link {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                overflow: hidden;
                text-overflow: ellipsis;
                @include breakpoint(medium down) {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                @include breakpoint(large up) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                }
            }
        }
    }
    &-icon {
        height: rem-calc(18);
        .add-to-wish-list {
            .small {
                height: rem-calc(16);
                width: rem-calc(12);
                margin: 0;
            }
        }
    }
    &-priceRow {
        margin-bottom: rem-calc(8);
        @include breakpoint(large up) {
            @include flexGenerator(row, space-between, center);
            flex-grow: 0;
        }
        .sales {
            &.dark {
                font-size: rem-calc(14);
                letter-spacing: 0;
                line-height: rem-calc(20);
                font-weight: 600;
            }
        }
    }
    &-promo {
        height: auto;
        line-height: rem-calc(12);
        font-size: rem-calc(9);
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: rem-calc(0.2);
        margin: 0;
        margin-bottom: rem-calc(5);
        @include breakpoint(large up) {
            font-size: rem-calc(10);
            line-height: rem-calc(14);
            margin-bottom: 0;
        }
    }
    &-color {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        &-label {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            font-weight: 400;
            &.inPlp {
                @include breakpoint(large up) {
                    display: none;
                }
            }
        }
        &-swatch {
            display: none;
            @include breakpoint(large up) {
                /*
                &-carousel {
                    display: block;
                    height: rem-calc(18);
                    width: rem-calc(100);
                    margin-left: rem-calc(25);
                    margin-right: rem-calc(25);
                    .slick-arrow {
                        width: rem-calc(25);
                        height: rem-calc(20);
                        background-color: #FFFFFF;
                        top: rem-calc(8);
                        &.slick-prev {
                            padding-right: rem-calc(5);
                            left: rem-calc(-25);
                            &:before {
                                content: url('../images/arrow-left-slick.svg');
                            }
                        }
                        &.slick-next {
                            padding-left: rem-calc(5);
                            right: rem-calc(-25);
                            &:before {
                                content: url('../images/arrow-right-slick.svg');
                            }
                        }
                        &.slick-disabled {
                            pointer-events: unset !important;
                            cursor: default;
                            opacity: .3;
                        }
                    }
                    .slick-list {
                        height: 19px;
                    }
                    .slick-track {
                        height: 19px;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .slick-arrow, .slick-arrow:before {
                        opacity: 1;
                        z-index: 1;
                    }
                    &.recommend {
                        width: rem-calc(60);
                        @media screen and (min-width: 1300px) {
                            width: rem-calc(100);
                        }
                    }
                }
                */
                &-static {
                    @include flexGenerator(row, flex-end);
                    flex-grow: 0;
                    height: rem-calc(18);
                    /*
                    &.recommend {
                        max-width: rem-calc(60);
                        @media screen and (min-width: 1300px) {
                            max-width: rem-calc(100);
                        }
                    }
                    */
                    .more-colors {
                        margin-left: rem-calc(4);
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        margin-top: rem-calc(2);
                        display: flex;
                        .plus {
                            padding-right: rem-calc(3);
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                        }
                    }
                }
            }
        }
        &-single {
            @include fixWidth(18px);
            height: rem-calc(18);
            margin: rem-calc(0 1);
            position: relative;
            display: block;
            &:not(:last-child) {
                margin-right: rem-calc(8);
                &.active {
                    margin-right: rem-calc(11);
                    width: rem-calc(21);
                    max-width: rem-calc(21);
                }
            }
            &.active img {
                border: 1px solid black;
                padding: rem-calc(3);
            }
            img {
                background-image: unset !important;
                border-radius: 50%;
                bottom: 0;
                box-sizing: content-box;
                height: rem-calc(16);
                left: 0;
                margin: auto;
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: rem-calc(16);
            }
        }
    }

    .loyalty-msg {
        margin-bottom: rem-calc(8);
    }
}

.recViewPdp {
    .productDescription-color-swatch {
        display: none;
    }
    .productDescription-color-label {
        display: block;
    }
}

.promo-scrolling-text {
    display: flex;
    @keyframes scrollingtext {
        from {
            -webkit-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
        }

        to {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &__content-text {
        display: inline-block;
        position: relative;
        text-decoration: none;

        span {
            display: inline-block;
            box-sizing: content-box;
            animation: scrollingtext 10s linear -1s infinite forwards;
            padding-right: 0.4rem;
            position: relative;
            white-space: nowrap;
            margin: 0;
        }
    }
}

.product-tile {
    @include breakpoint(large up) {
        &:hover {
            .pd-scrolling-text__content-text-p {
                -webkit-animation-play-state: paused;
            }
        }
    }

    .pd-scrolling-text {
        visibility: hidden;
        display: flex;
        padding: rem-calc(3 0);
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;

        @keyframes scrollingtext {
            from {
                -webkit-transform: translate3d(0%, 0, 0);
                transform: translate3d(0%, 0, 0);
            }

            to {
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }
        }

        font-size: 0;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;

        &__content-text {
            display: inline-block;
            position: relative;
            text-decoration: none;

            &-p {
                display: inline-flex;
                align-items: center;
                box-sizing: content-box;
                animation: scrollingtext 10s linear -1s infinite forwards;
                position: relative;
                white-space: nowrap;
                margin: 0;
                font-weight: 600;
                font-size: rem-calc(10);
                line-height: rem-calc(17);
                font-family: $secondary-font;
                text-transform: uppercase;
            }

            &-icon {
                display: inline-block;
                margin: rem-calc(0 8);
                border-radius: 50%;
                height: rem-calc(5);
                width: rem-calc(5);
            }
        }
    }
}
