.quantity {
    @include breakpoint(medium down) {
        margin-top: 0;
    }
    &-button {
        color: black !important;
        cursor: pointer;
        @include breakpoint (small down) {
            transform: scale(.8);
        }
    }

    &-value {
        display: inline-block;
    }
    &-circle {
        outline: none;
        display: inline-block;
        background: transparent;
        border: 1px solid $border-gray;
        appearance: none;
        width: rem-calc(21);
        height: rem-calc(21);
        line-height: rem-calc(21);
        border-radius: 50%;
        font-size: rem-calc(18);
        text-align: center;
        cursor: pointer;
        font-weight: $global-weight-bold;
    }
}

.bonus-product-modal {
    & .quantity {
        margin-top: rem-calc(16);
        @include breakpoint(medium down) {
            margin-top: 0;
        }
    }
}
.size-selectore {
    height: rem-calc(41);
    .quantityButtons {
        display: inline-flex;
    }
}
