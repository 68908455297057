
.fis-select-size-modal,
.prod-select-size-modal {
    z-index: 2051; // above quickview

    .tingle-modal-box {
        max-width: rem-calc(440);
        h3 {
            font-size: rem-calc(16);
            letter-spacing: 2.0;
            font-weight: $global-weight-medium;
            line-height: normal;
            margin-bottom: rem-calc(30);
      }
    }

    button {
        margin-right: 0;
    }

    ul {
        list-style-type: none;
        width: 100%;
        margin: rem-calc(13 0 0);
        text-align: left;
    }

    li {
        display: inline-block;

        button {
            padding: rem-calc(5 8 6 8);
            font-size: rem-calc(16);
            font-weight: $global-weight-dark;
            cursor: pointer;
            border: rem-calc(1) solid gray(6);
            transition: border-color 0.35s ease;
            margin: rem-calc(7 14 7 0);
            letter-spacing: 0.7px;
            color: black;

            &[disabled] {
                opacity: 0.4;
            }

            &.selected,
            &:focus,
            &:active,
            &:hover {
                outline: none;

                &:not([disabled]) {
                    border-bottom: 2px solid  $dark-gray;
                    padding-bottom: rem-calc(5);
                }
            }
        }
    }
}