@import "foundation";

.modal-mobile-is-open {
    .background-size-mobile-modal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 2050;
        background: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(rem-calc(80));

        @include breakpoint(large up) {
            display: none;
        }
    }

    .wrapperModalFindYourSize {
        z-index: 3020;
    }
}

.bonus-product-backgroud-container {
    .background-size-mobile-modal {
        height: calc(100vh - 58px);
    }
}

.pdp-modal {
    &.tingle-modal-box {
        @include breakpoint (medium up) {
            width: inherit;
        }
    }
    .tingle-modal-box__content {
        .modal__closeIconSizeGuide {
            width: initial;
            height: initial;
            position: absolute;
            right: rem-calc(16);
            top: rem-calc(16);
            .close-icon {
                width: rem-calc(16);
                height: rem-calc(16);
            }
        }
        .modalFindYourSize {
            @include breakpoint (medium up) {
                width: rem-calc(980);
            }
            .pdp-nav-container {
                @include breakpoint (medium up) {
                    width: 20.4%;
                    background-color: #F5F5F5;
                }
                background-color: #FFFFFF;
                .pdp-nav {
                    padding-left: 0!important;
                    .pdp-title-wrapper {
                        padding: rem-calc(32 0 32 24);
                        border-bottom: 1px solid #E6E6E6;
                        @include breakpoint (medium down) {
                            padding-bottom: rem-calc(16);
                            padding-left: rem-calc(20);
                            border-bottom: 0;
                        }
                        .pdp-title {
                            color: #F67A1A;
                            margin: 0;
                            line-height: rem-calc(22);
                            font-weight: 500;
                        }
                    }
                    .pdp-categories {
                        .pdp-category {
                            @include breakpoint (medium down) {
                                display: none;
                            }
                            border-bottom: 1px solid #E6E6E6;
                            .pdp-category-toggle {
                                .minus, .plus {
                                    font-size: rem-calc(24);
                                    color: #F67A1A;
                                    font-weight: bold;
                                }
                                .minus {
                                    display: none;
                                }
                                .plus {
                                    display: inline-block;
                                }
                            }
                            &.is-open {
                                @include breakpoint (medium down) {
                                    display: block;
                                }
                                .pdp-category-toggle {
                                    .minus {
                                        display: inline-block;
                                    }
                                    .plus {
                                        display: none;
                                    }
                                }
                            }
                            .pdp-category-header {
                                @include breakpoint (medium down) {
                                    display: none;
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                                font-weight: bold;
                                font-size: rem-calc(14);
                                position: relative;
                                padding: rem-calc(16 32 16 24);
                                .pdp-category-toggle {
                                    position: absolute;
                                    right: rem-calc(16);
                                    top: rem-calc(14);
                                }
                            }
                            .pdp-category-body {
                                display: none;
                                @include breakpoint (medium down) {
                                    overflow: auto;
                                    &::-webkit-scrollbar {
                                        height: rem-calc(0);
                                        background-color: transparent;
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        height: rem-calc(0);
                                        background-color: transparent;
                                    }
                                }
                                &.active {
                                    display: block;
                                }
                                .pdp-subcategory-link {
                                    transition: color 0.2s ease;
                                    position: relative;
                                    @include breakpoint (medium down) {
                                        &:first-child {
                                            margin-left: rem-calc(20);
                                        }
                                        display: inline-block;
                                        padding: rem-calc(0);
                                        padding-bottom: rem-calc(15);
                                        margin: rem-calc(0 8);
                                    }
                                    &:hover {
                                        color: #000000;
                                        cursor: pointer;
                                        font-weight: 500;
                                    }
                                    &.active {
                                        color: #000000;
                                        font-weight: 500;
                                        @include breakpoint (medium down) {
                                            &::after {
                                                content: '';
                                                width: 100%;
                                                border-bottom: rem-calc(2) solid #000000;
                                                height: rem-calc(0);
                                                position: absolute;
                                                bottom: 0;
                                                left: 0;
                                            }
                                        }
                                    }
                                    display: block;
                                    padding: rem-calc(8 0 8 32);
                                    color: #999999;
                                    letter-spacing: 0;
                                    &:first-child {
                                        padding-top: 0;
                                    }
                                    &:last-child {
                                        padding-bottom: rem-calc(15);
                                    }
                                    &.selected {
                                        color: #262626;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .pdp-body-container {
                @include breakpoint (medium up) {
                    width: 79.6%;
                    height: 90vh;
                    max-height: 90vh;
                }
                background-color: #FFFFFF;
                .pdp-body {
                    &.size-calculator-container {
                        .pdp-categories {
                            @include breakpoint (medium down) {
                                margin-top: rem-calc(16);
                            }
                        }
                        .pdp-section {
                            .pdp-text-container {
                                display: flex;
                                flex-wrap: wrap;
                                .description-list, .size-calculator {
                                    flex: 1 0 100%;
                                }
                                @include breakpoint (medium down) {
                                    margin: 0;
                                    padding: rem-calc(0 24);
                                }
                            }
                            .pdp-table-container.size-guide-table-container {
                                display: none;
                            }
                            @include breakpoint (medium down) {
                                margin: 0;
                                .pdp-text-container {
                                    order: 1;
                                }
                                .how-to-text {
                                    order: 2;
                                    padding: rem-calc(0 24);
                                    margin-bottom: rem-calc(24);
                                    .text {
                                        margin: 0;
                                    }
                                }
                                .pdp-image-container {
                                    order: 3;
                                    margin: rem-calc(0 0 8 0);
                                    padding: rem-calc(0 24);
                                }
                                .description-list {
                                    order: 4;
                                    margin: rem-calc(16 0 24 0);
                                    padding: rem-calc(0 24);
                                }
                                .pdp-table-container.size-guide-table-container {
                                    display: block;
                                    order: 5;
                                }
                            }
                        }
                    }
                    margin: rem-calc(32 40);
                    @include breakpoint (medium down) {
                        margin: rem-calc(24 0 56);
                    }
                    .pdp-body-title {
                        font-size: rem-calc(16);
                        line-height: rem-calc(22);
                        @include breakpoint (medium down) {
                            margin-left: rem-calc(24);
                            margin-right: rem-calc(24);
                        }
                        text-transform: uppercase;
                    }
                    .pdp-table-container {
                        background-color: initial;
                        padding: 0;
                        margin-bottom: rem-calc(40);
                        @include breakpoint (medium down) {
                            margin-bottom: rem-calc(32);
                        }
                        .pdp-table.pdp-size-guide-table {
                            overflow: auto;
                            tr {
                                td:first-child, th:first-child {
                                    background-color: #F5F5F5;
                                }
                                td, th {
                                    text-align: center;
                                    border-bottom: 1px solid #E6E6E6;
                                    font-size: rem-calc(12);
                                    line-height: rem-calc(35);
                                    min-width: rem-calc(130);
                                }
                                td {
                                    @include breakpoint (medium up) {
                                        font-size: rem-calc(10);
                                    }
                                }
                                th {
                                    text-transform: uppercase;
                                    color: #F67A1A;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .pdp-section {
                        @include breakpoint (medium down) {
                            margin: rem-calc(0 24);
                        }
                        flex-wrap: wrap;
                        .text-wrapper {
                            font-weight: 400;
                            display: block;
                            width: 100%;
                            &.bold {
                                font-weight: bold;
                            }
                            &.medium {
                                font-weight: 500;
                            }
                        }
                        .text {
                            font-size: rem-calc(14);
                            font-weight: inherit;
                        }
                        .link {
                            font-weight: inherit;
                        }
                        .pdp-text-container {
                            @include breakpoint (medium down) {
                                order: 2;
                            }
                        }
                        .size-guide-table-container {
                            width: 100%;
                            @include breakpoint (medium down) {
                                order: 3;
                            }
                        }
                        .size-calculator {
                            margin: rem-calc(40 0 16 0);
                            @include breakpoint (medium down) {
                                margin: rem-calc(8 0 16 0);
                            }
                        }
                        .pdp-image-container {
                            text-align: right;
                            @include breakpoint (medium down) {
                                order: 1;
                                width: 100%;
                                text-align: center;
                                margin-bottom: rem-calc(24);
                            }
                        }
                    }
                    .description-list {
                        margin-top: rem-calc(16);
                        .description-list-item {
                            .list-item-title-wrapper {
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                margin-bottom: rem-calc(8);
                                .list-item-circle {
                                    width: rem-calc(20);
                                    height: rem-calc(20);
                                    background-color: #F67A1A;
                                    border-radius: 50%;
                                    text-align: center;
                                    font-size: rem-calc(12);
                                    color: #FFF;
                                }
                                .description-title {
                                    margin-left: rem-calc(8);
                                    color: #F67A1A;
                                }
                            }
                            .description {
                                margin-bottom: rem-calc(24);
                                font-weight: 500;
                                font-size: rem-calc(14);
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes modalShow {
    from {
        bottom: -100%;
    } 
    to {
        bottom: 0;
    }
}

.notify-me-quick-add, .notify-me-mobile, .notifyMeModal {
    &.visible {
        display: block;
    }
    .size-mobile-modal-content {
        .spacer-40 {
            @include breakpoint (medium down) {
                height: rem-calc(40);
            }
        }
        @include breakpoint (medium down) {
            padding: rem-calc(24 20);
        }
        .modalTitle {
            @include breakpoint (medium up) {
                width: 100%;
            }
            .notify-title {
                word-break: keep-all; // Keep CJK characters in one line.
                font-size: rem-calc(16);
                padding: rem-calc(0 25);
                font-weight: normal;
                color: #262626;
                line-height: initial;
                margin-bottom: rem-calc(16);
                @include breakpoint (medium down) {
                    font-weight: 500;
                    font-size: rem-calc(16);
                    padding: 0;
                }
            }
            .notify-subtitle {
                font-size: rem-calc(14);
                letter-spacing: 0;
                margin-bottom: rem-calc(16);
                line-height: initial;
                @include breakpoint (medium up) {
                    padding: rem-calc(0 24);
                    line-height: rem-calc(20);
                }
            }
        }
        .notifyBody {
            @include breakpoint (medium up){
                margin: rem-calc(0 25);
            }
            .notify-size {
                font-weight: bold;
                margin-bottom: rem-calc(24);
                justify-content: center;
                width: 100%;
                @include breakpoint (medium up) {
                    margin: rem-calc(16 0);
                }
            }
            .notify-input-wrapper {
                width: 100%;
                @include breakpoint (medium up){
                    margin: rem-calc(0 25);
                }
                #emailNotify {
                    @include breakpoint (medium up) {
                        margin: rem-calc(8 0);
                    }
                }
            }
            .emailError {
                .red {
                    color: #FD3D3D;
                    font-size: rem-calc(12);
                }
            }
            .success-message {
                color: #262626;
                background-color: #A1C6C2;
                letter-spacing: rem-calc(0.24);
                @include breakpoint (medium down) {
                    margin: rem-calc(0 0 10 0);
                    padding: rem-calc(8 12);
                }
                @include breakpoint (medium up) {
                    width: 100%;
                    margin: rem-calc(8 0);
                }
                #comingSoonGreen {
                    @include breakpoint (medium down) {
                        font-size: rem-calc(12);
                        margin-bottom: 0;
                    }
                }
            }
            #emailNotify {
                &.error {
                    border-color: #FD3D3D;
                }
            }
        }
        .notify-actions {
            .continue-shopping-link {
                width: 100%;
                margin-bottom: 0;
            }
            .actions-wrapper {
                margin-top: rem-calc(8);
                @include breakpoint (medium up){
                    margin-left: rem-calc(25);
                    margin-right: rem-calc(25);
                    margin-top: rem-calc(24);
                }
            }
            .continueShopping {
                @include breakpoint (medium down) {
                    margin-bottom: rem-calc(16);
                }
            }

            .notifyButton {
                margin-bottom: rem-calc(16);
                font-size: rem-calc(14);
            }
            #findInStore-modal {
                margin-bottom: rem-calc(16);
                @include breakpoint (medium down) {
                    font-weight: medium;
                    margin-bottom: 0;
                    width: 100%;
                }
            }
            .privacy {
                font-size: rem-calc(10);
                text-align: center;
                &-link {
                    color: #999999;

                }
            }
        }
    }
}

.size-mobile-modal, .notify-me-mobile, .notify-me-quick-add {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    border-radius: 20px 20px 0 0;
    background: $white;
    display: none;
    z-index: 2052;
    &-header {
        padding: rem-calc(16 20);
        @include breakpoint(medium only){
            padding: rem-calc(18 40);
        }
        background-color: white;
        border-radius: 20px 20px 0 0;

        .size-finder {
            font-size: rem-calc(14);
            line-height: rem-calc(14);
        }
    }

    &-content {
        @include breakpoint (medium up) {
            padding: rem-calc(40 0);
        }

        @include breakpoint(medium only){
            padding: rem-calc(20 40 25);
        }
        @include breakpoint(medium down) {
            padding-top: rem-calc(16);
        }

        .size-mobile-modal-label {
            margin: 10px 0 12px 0;
        }

        .size-modal-attributes {
            margin: 10px 0;

            &-label {
                padding: 0;
                margin-right: 5px;
                display: inline-block;
                vertical-align: top;

                .link-attribute {
                    border-bottom: 1px solid transparent;
                    padding: 10px;
                    display: block;
                    margin-bottom: 5px;
                    text-align: center;

                    &.unselectable {
                        color: $medium-light-gray;
                    }

                    &.text-dark-grey {
                        color: $medium-light-gray;
                        svg {
                            &.alertNotify{
                                display: block;
                                margin-top: -3px;
                                width: rem-calc(20);
                                height: rem-calc(20);
                            }
                        }
                    }

                    &.selectable {
                        svg {
                            &.alertNotify{
                                display: none;
                            }
                        }
                    }


                    &.underlined{
                        padding-bottom: rem-calc(3);
                    }

                    svg {
                        &.alertNotify{
                            display: none;
                        }
                    }

                }
            }
        }

        .availability {
            margin-bottom: 0;
            display: block;
            margin-top: 5px;

            .availability-msg {
                display: block;
                font-size: rem-calc(11);
                line-height: rem-calc(14);
                color: rgba(241,241,242,1);
            }
        }
    }

    &.modal-mobile-size-show {
        z-index: 2052;
        animation-name: modalShow;
        animation-duration: .3s;
    }
}
.right-side-modal.free-gift-modal{
    .size-mobile-modal, .notify-me-mobile, .notify-me-quick-add {
        @include breakpoint (medium down){
            z-index: 2053;
        }
    }
}

.size-mobile-modal-header.bottom-section{
    border-radius: 0;
}
.size-mobile-modal-attributes {
    .active .link-attribute {
        border-color: $black;
        background-color: white;
    }
}

.modal-mobile-is-open {
    .size-mobile-modal {
        display: block;
        background-color: rgba(255, 255, 255, 0.75);
        /* backdrop-filter: blur(80px); */
        @include breakpoint(large up) {
            display: none;
        }
    }
}

.size-mobile-modal-btn-warpper {
    background: $white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    position: fixed;
    padding: 20px 40px;
    width: 100vw;
    bottom: 0;
    left: 0;
    z-index: 2000;

    @include breakpoint(large up) {
        display: none;
    }
    @include breakpoint(small down) {
        padding: 20px 20px;
    }

    .button {
        @include breakpoint(medium down) {
            margin: 0;
        }
    }
}