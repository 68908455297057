.scalapay-widget {
    font-size: rem-calc(12);
    @include breakpoint(small down) {
        margin-left: 1.5rem;
    }
}

.page[data-action="Cart-Show"] {
    .scalapay-widget {
        margin-top: rem-calc(-15);
    }
}

// PDP mobile
.scalapay-pricing-mobile {
    padding: rem-calc(0 15);
}

// Checkout
.js-billing-scalapay-price {
    display: block;
    margin-bottom: rem-calc(6);
}

// ScalaPay Modal
.sp_modal__container {
    .sp_modal__logo , .sp_modal__logo_image {
        svg {
            width: initial;
        }
    }
}