@import "../../components/markerinfowindow";

.page[data-action="Cart-Show"] {
    .findinstore,.pickuppoint {
        .modal-title {
            text-transform: unset;
        }
    }
    .pickuppoint{
        .modal-title {
            width: 90%;
        }
    }
}

.findinstore,.pickuppoint {
    @include breakpoint (small down) {
        background: unset;
    }
    .modal-title {
        font-size: rem-calc(16);
        line-height: rem-calc(30);
        letter-spacing: 0.2px;
        text-transform: uppercase;
        &.find-in-store-title{
            font-weight: $global-weight-bold;
        }
    }

    &-wrapper {
        @include breakpoint (medium up) {
            width: 96vw;
        }
        @include breakpoint (small down) {
            padding: 0;
            background: rgba(255,255,255,0.75);
            backdrop-filter: blur(20px);
            max-height: 100vh;
            max-width: 100vw;
            width: 100vw;
        }
        padding: rem-calc(23 35);
        position: relative;

        input[name="query"], .sps-input-pup {
            // use pxs to join with next border
            margin-right: -1px;
            border: none;
            border-bottom: $input-border;
        }
    }

    &-close {
        position: absolute;
        top: rem-calc(12);
        right: rem-calc(16);
        // override tingle styles
        margin-right: 0 !important;
        font-size: rem-calc(48);
        line-height: 1;
        font-weight: 100;
        cursor: pointer;
        z-index: 1;

        @include breakpoint (medium up) {
            right: rem-calc(32);
        }
    }

    .tingle-modal-box {
        @include breakpoint (medium up) {
            max-width: rem-calc(1040);
            height: rem-calc(573);
        }
    }

    #findinstore-usecurrentposition {
        font-weight: $global-weight-dark;

        &.disabled {
            color: $medium-light-gray;
            border-color: $medium-light-gray;
            cursor: not-allowed;
        }
    }

    &__usecurrentposition {
        line-height: 2.5;
        text-align: center;
        min-width: rem-calc(24);
    }

    &__input-group {
        &.has-loader {
            transition: padding 0.3s ease;

            &.loading {
                padding-right: rem-calc(42);
            }
        }

        [type="submit"] {
            position: relative;
            left: rem-calc(-24);
            margin-right: 0;
        }
    }
    #CLSS-usecurrentposition{
        font-size: rem-calc(14);
        font-weight: 600;
    }

    // Google's map
    #map {
        height: 0;
        min-height: rem-calc(540);
        max-height: rem-calc(573);
        background-color: $light-gray;
        display: block;

        .js-choose-store-infowindow, .store-description, .store-phone-detail-box {
            font-family: $body-font-family;
        }
        @include breakpoint (medium up) {
            height: 100%;
        }

        @include breakpoint(small down) {
            height: rem-calc(365);
        }

        .store-description {
            font-size: rem-calc(13);
            margin: 0;
            &.store-address1 {
                font-weight: 500;
            }
        }

        .store-hours {
            &-open {
                font-weight: bold;
                color: #67BE7A;
            }

            &-closed {
                font-weight: bold;
                color: #C7383C;
            }
        }
    }

    &-results {
        @include breakpoint(large up) {
            height: rem-calc(377);
            overflow-y: auto;
            overflow-x: hidden;
        }

        .bordered-top {
            border-top: 1px solid #ECECEC;
            .store-bottom-phone {
                border-right: 1px solid #ECECEC;
            }

            .cell {
                width: 50%;
                height: rem-calc(35);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .result {
            width: 96%;
            margin-bottom: rem-calc(20);
            border: 1px solid #ECECEC;
            padding-top: rem-calc(10);

            .inner {
                padding: rem-calc(18 14);
                &.for-collissimo{
                    @include breakpoint (medium up){
                        padding: rem-calc(18 14 18 0);
                    }
                }
            }

            &--selected {
                border-color: $black;
                .bordered-top {
                    border-color: $black;
                }
                .cell-border-link-center{
                    border-top: 1px solid $black;
                }
            }

            .js-store-phone {
                font-weight: 600;
            }

            .button {
                margin-bottom: 0;
                line-height: rem-calc(30);
            }
            .js-choose-store {
                @include breakpoint (small down){
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            i {
                &.fas {
                    margin: rem-calc(4);
                    margin-top: rem-calc(9);
                }
            }
        }

        & > p {
            font-size: rem-calc(14);
            font-weight: $global-weight-dark;
            margin-bottom: rem-calc(8);
        }

        .info_note {
            font-size: rem-calc(14);
            font-weight: 400;
        }

        .fa-map-marker {
            .label-count {
                color: $white;
                position: relative;
                font-size: rem-calc(10);
                left: rem-calc(3.2);
                top: rem-calc(-20);

                @include breakpoint(medium down)  {
                    left: rem-calc(-15);
                    top: rem-calc(-6);
                }

                @media only screen and (max-width: 487px) {
                    left: rem-calc(3.2);
                    top: rem-calc(-20);
                }
            }
            .marker-icon {
                margin-right: rem-calc(5);
                vertical-align: top;
            }
        }

        .store-details {
            font-weight: 400;
            .store-name, .js-store-city,.js-store-postal-code, .additional-info-store, .pickup-name, .js-pickup-city, .js-pickup-postalcode{
                font-size: rem-calc(12);
            }
            p {
                display: block;
                margin-bottom: rem-calc(4);
            }

            .text-brand-green{
                color: $success;
            }

            .text-brand-red{
                color: red;
            }

            .js-store-address-row1 {
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.1);
            }
            .js-pickup-address-row1 {
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.1);
            }

            .js-store-distance {
                text-align: right;
            }

            .js-toggle-store-hours {
                font-weight: 600;

                .toggle-store-hours-dropdown.flip {
                    transform: rotate(180deg);
                }
            }

            .store-hours {
                font-size: rem-calc(12);

                &-open {
                    color: #67BE7A;
                }

                &-closed {
                    color: #C7383C;
                }

                &-next-phase {
                    font-weight: normal;
                }

                .store-hours-list {
                    ul {
                        list-style: none;
                        margin: 0;

                        .active {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .iphone-disable-zoom {
        font-size: rem-calc(16);
    }
}

.cc-pickuppoint{
    .cc-service-types-radio{
        width: rem-calc(20);
        height: rem-calc(20);
    }
    .cc-pickup-point-service-types{
        height: rem-calc(235);
    }
}

@import "_selectsizemodal";

#locationsInCheckout {
    .store-details {
        display: inline-block;
    }

    .marker-icon {
        margin-right: rem-calc(5);
        vertical-align: top;
    }
}

.nearby-stores {
    &__icon {
        display: inline-block;
        margin-right: rem-calc(16);
    }

    .result {
        &:not(:first-child) {
            padding-top: rem-calc(24);
        }

        border-bottom: 1px solid $border-gray;
        margin: rem-calc(10 10 10 0);

        .button {
            &.wide {
                min-width: 8rem;
            }
        }
    }
}

#checkout-main {
    .fa-map-marker {
        .label-count {
            @include breakpoint (small down){
                left: rem-calc(-15);
                top: rem-calc(-5);
            }
        }
    }
}

.prod-select-size-modal {
    .find-in-store-size-select-btn {
        display: none;
    }
}

.fis-select-size-modal {
    .add-to-cart {
        display: none;
    }
}

#pickupPointMap, #pickupPointMapInPost, #pickupPointMapPPL, #pickupPointMapDHL, #pickupPointMapDPD, #pickupPointMapBRT, #pickupPointMapHermes, #pickupPointMapCLSS, .cc-pickupPointMapStandard {
    height: 0;
    min-height: rem-calc(540);
    max-height: rem-calc(573);
    background-color: $light-gray;
    display: none;

    @include breakpoint (medium up) {
        display: block;
        height: 100%;
    }
    @include breakpoint(small up) {
        height: 28.125em;
        display: block;
    }
}
.pickup-input-group-field{
    height: 40px;
}
.pickup-input-group-button{
    height: 60px;
    margin-top: 20px;
}
.js-pup-summary{
    margin-top: 10px;
    margin-bottom:10px;
}
.inpost-search, .ppl-search, .dhl-search, .dpd-search {
    margin-top: rem-calc(-6)!important;
}
.pickup-input-bar{
    border: none;
    border: 1px solid rgb(211, 211, 211);
    margin-right: 10px;
}
.label-required-pickup{
    margin-left: 245px;
    color:rgb(47, 79, 79);
    font-size: 11px;
    position: absolute;
    @include breakpoint (small down) {
        margin-top:45px;
        margin-left: 0px;
    }
}
.pickupboolean{
    padding-right: 1.14286rem;
}

.scrollbar-custom {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: rem-calc(5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $black;
        border-radius: rem-calc(0);
        height:rem-calc(200);
    }

    &::-webkit-scrollbar-track {
        background-color: $light-gray;
    }
}

#sps-pup-form {
    .btn-findinstore-store-search {
        top: rem-calc(63);
    }
}

.pickup-point-button-brt {
    margin-top: rem-calc(23);
}

.findinstore,
.pickuppoint{
    @include breakpoint (medium up){
        background: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(20px);
    }
    @include breakpoint (small down){
        padding: rem-calc(0 16);
    }
    .findinstore-wrapper,
    .pickuppoint-wrapper{
        @include breakpoint (medium up){
            padding: 0;
            overflow-x: hidden;
        }
        .map-section{
            @include breakpoint (medium up){
                margin: 0;
            }
        }
        .form-section{
            @include breakpoint (medium up){
                margin: 0;
                padding: rem-calc(40 40 0 40);
            }
            @include breakpoint (small down){
                margin-left: 0;
                margin-right: 0;
                padding: rem-calc(24 16 0 16);
            }
            .modal-header{
                margin-bottom: rem-calc(12);
            }
            .input-group-button{
                display: block;
                margin-bottom: rem-calc(8);
            }
            .close{
                font-size: unset;
            }
        }
    }
    .findinstore-results{
        margin-top: rem-calc(24);
        .info_note{
            @include breakpoint (medium down){
                margin-bottom: rem-calc(24);
            }
        }
    }
}

.mapPoint__price {
    display: none !important;
}

#pickupPointPDB {
    #PDB-summary {
        margin: 0;
        padding: rem-calc(24 24);
    }

    .pickup-point-service-types {
        font-size: rem-calc(14);
        padding: rem-calc(24 0);
        display: flex;
        flex-direction: column;
        gap: rem-calc(12);

        .boolean-field {
            width: 100%;
            margin-bottom: 0;
            cursor: pointer;
        }

        .radio-input {
            padding-left: rem-calc(20);
            margin-right: rem-calc(12);
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }
}