@import "swatch-colors";
@import "quantity";

.selectable-swatches__circle.boolean img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.swatch-circle.unselectable {
  opacity: 0.2;
  cursor: not-allowed;
}

.swatch-circle {
  display: inline-block;
  border-radius: 50%;
  width: rem-calc(30);
  height: rem-calc(30);
}

.swatch-boolean {
  display: inline-block;
  width: rem-calc(42);
  height: rem-calc(30);
  text-align: center;

  margin: 0;
  padding: 0;
  line-height: rem-calc(32);
  @include breakpoint(small down) {
    width: rem-calc(40);
    height: rem-calc(40);
  }
  .radio-input {
    padding-left: 0;
    position: absolute;
    left: 0;
    bottom: rem-calc(5);
  }

  .radio-input:before {
    content: none;
    display: none;
  }

  .radio-input:after,
  input[type="radio"]:checked + .radio-input:after {
    height: rem-calc(2);
    width: rem-calc(21);
    top: auto;
    left: auto;
    bottom: rem-calc(-8);
    border: none;
    border-radius: 0;
  }

  input[type="radio"]:checked ~ .radio-label {
    font-weight: $global-weight-bold;
  }
}
