.gm-style {
    .js-choose-store-infowindow, .js-choose-point-infowindow {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        min-width: rem-calc(80);
        word-break: break-word;
        word-wrap: break-word;
    }
    .gm-style-iw-c {
        border-radius: 0;
        padding: rem-calc(15);
        border: 1px solid $border-gray;
        box-shadow: 0 2px 8px rgba($black,0.1);
    }

    .gm-style-iw-t {
        &::after {
            display: none;
        }
    }
}
