@import '../pdp/product';
@import 'bonusproduct';

.product-tile:hover {
    .hover-info-bag-wishlist, .quick-buy-sizes {
        @include breakpoint (large up) {
            display: inline-block;
        }
    }
}
/*.recViewPdp, */.recViewPlp {
    .quick-buy-sizes {
        display: none !important;
    }
}
/*
.hover-info-bag-wishlist {
    display: none;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    z-index: 3;
    bottom: rem-calc(15);
    left: rem-calc(12);
    width: calc(100% - 24px);
    overflow: hidden;
    text-align: left;
    padding: rem-calc(15 18);
    .wishlist-heart-on-right {
        float: right;
    }
    .add-to-bag {
        font-size: rem-calc(14);
        vertical-align: middle;
    }
}
*/
.quick-buy-sizes {
    display: none;
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    z-index: 1;
    bottom: rem-calc(-1);
    left: 0;
    width: 100%;
    overflow: hidden;
    padding: rem-calc(16 16 8);
    &-container {
        @include flexGenerator(column, flex-start, center);
    }
    &-label {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        text-transform: uppercase;
        margin-bottom: rem-calc(16);
        font-weight: 600;
    }
    &-row {
        @include flexGenerator(row, center, flex-start);
        flex-wrap: wrap;
        margin: rem-calc(0 -5);
        padding: rem-calc(0 72);
    }
    &-item {
        line-height: rem-calc(20);
        font-size: rem-calc(14);
        margin: rem-calc(0 16 8);
        color: #646464;
        &:hover {
            color: #000;
        }
        &.disable,
        &.disable:hover {
            cursor: not-allowed;
            color: #BEBEBE;
            text-decoration: line-through;
        }
    }
}
.product-quickview {
    .carousel-inner {
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        &.snap-scroll {
            scroll-snap-type: y mandatory;
        }
    }
}
.tingle-modal.bonus-product-modal {
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(80px);
    .tingle-modal-box {
        background: white;
        @include breakpoint (medium up) {
            height: auto;
            width: 63vw;
        }
        &__content {
            .close-custom {
                position: absolute;
                top: rem-calc(20);
                right: rem-calc(20);
                font-size: initial;
                cursor: pointer;
                margin-right: 0;
                @include breakpoint(medium down) {
                    top: rem-calc(20);
                    right: rem-calc(20);
                }
            }
            .product-quickview .margin-wrapper-description {
                margin: rem-calc(40 38 20 40);
            }

        }
    }
}

.bonus-product-carousel-wrapper{
    .bonus-product-modal{
        .tingle-modal-box__content{
            .product-quickview.product-freegift{
                .primary-images{
                    @include breakpoint (medium down){
                        overflow: auto;
                    }
                }
            }
        }
    }
}

.quickview-modal,
.quickview-modal-edit-cart-product,
.bonus-product-modal {
    z-index: 2054;
    .primary-images {
        .slick-prev:before, .slick-next:before {
            font-family: 'slick';
            font-size: rem-calc(20);
            line-height: 1;
            opacity: .75;
            color: $black;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            z-index: 1200;
        }
        .slick-prev {
            left: 0;
            z-index: 1200;
        }
        .slick-next {
            right: 0;
            z-index: 1200;
        }
    }

    // Custom tingle
    .tingle-modal-box {
        max-width: rem-calc(891);
        overflow: auto;

        &__content {
            .close {
                font-weight: 100;
                font-size: rem-calc(48);
            }
            .close-custom {
                position: absolute;
                top: rem-calc(8);
                right: rem-calc(16);
                font-size: rem-calc(26);
                cursor: pointer;
                margin-right: 0;
            }
        }
    }

    .tingle-modal-box__content {
        padding: rem-calc(0);
        .product-quickview {
            background-color: $white;
            margin-top: 0;

            &.cc-quickAddModal-box {
                .primary-images {
                    @include breakpoint(medium down) {
                        max-width: unset;
                    }
                }
            }
            .primary-images {
                max-width: rem-calc(380);
                overflow: hidden;

                .carousel-item {
                    margin-bottom: 0;
                    max-width: rem-calc(380);
                    width: auto;
                    height: auto;
                    scroll-snap-align: start;
                }
            }
            .margin-wrapper-description {
                margin-right: rem-calc(25);
                margin-top: rem-calc(27);
                margin-bottom: rem-calc(0);
                margin-left: rem-calc(18);
                position: relative;

                .quickViewModal {
                    .carousel-select-color {
                        .color-variation {
                            width: rem-calc(30);
                            .selectable-swatches__circle {
                                height: rem-calc(25);
                                width: rem-calc(25);
                                &:before {
                                    width: rem-calc(29);
                                    height: rem-calc(29);
                                }
                                &.selected {
                                    &:after {
                                        width: rem-calc(25);
                                        height: rem-calc(25);
                                    }
                                }
                            }
                        }
                    }
                    .attribute-container {
                        &.promo-message-pdp {
                            margin-top: rem-calc(20);
                        }
                    }

                    .m-d-price-container {
                        margin-top: rem-calc(15);
                        margin-bottom: rem-calc(10);
                        .price {
                            font-size: rem-calc(20);
                            line-height: rem-calc(20);
                            .strike-through {
                                .value {
                                    font-size: rem-calc(18);
                                }
                            }
                        }
                    }

                    .wishlist-icon {
                        display: block;
                        right: rem-calc(9.8);
                    }
                    .attribute-container.quantity.container-flex-desktop-quantity-message {
                        display: flex;
                        flex-flow: row wrap;
                    }
                }

                .product-name {
                    padding: 0;
                }

                &.add-freegift {
                    @include breakpoint(small down) {
                        background-color: $white;
                        z-index: 998;
                        margin-left: 0;
                        width: 100vw !important;
                        top: initial;
                        margin-top: 0;
                        padding-top: rem-calc(11);
                        @supports (padding-bottom: env(safe-area-inset-bottom)) {
                            padding-bottom: env(safe-area-inset-bottom);
                        }
                    }
                    .m-d-price-container {
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                        font-weight: 600;
                        margin-top: rem-calc(8);
                        margin-bottom: 0;
                        @include breakpoint(small down) {
                            padding: rem-calc(0 45 4 18);
                        }
                        &.bonus-price-container{
                            margin-bottom: rem-calc(20);
                        }
                    }
                    .modal-quick-size-quantity {
                        padding-top: rem-calc(20);
                        align-items: flex-start;
                        @include breakpoint(small down) {
                            position: fixed;
                            bottom: 3.5rem;
                            left: 0;
                            z-index: 2;
                            margin-left: 0;
                            width: 100vw !important;
                            top: initial;
                        }
                        &.bonus-product-modal-container{
                            align-items: unset;
                        }
                    }

                    h4.product-name {
                        height: auto;
                        height: auto;
                        @include breakpoint(small down) {
                            padding: rem-calc(12 45 4 18);
                            font-size: rem-calc(14);
                            min-height: initial;
                        }
                        a {
                            font-weight: 400;
                            padding-bottom: rem-calc(8);
                            font-size: rem-calc(20);
                            line-height: rem-calc(26);
                        }
                    }

                }
                .carousel-select-size {
                    .product-content-size {
                        margin-top: rem-calc(20);
                    }
                }
                .margin-wrapper-description {
                    margin-right: rem-calc(60);
                    margin-top: rem-calc(30);
                    position: relative;
                    .modal-quick-size-quantity {
                        position: absolute;
                        background-color: $white;
                        border: rem-calc(1) solid $border-gray;
                        bottom: rem-calc(-20);
                        padding: rem-calc(15 24);
                        .pdp-size-box {
                            margin-top: 0;
                        }
                        button.close-select-size {
                            margin-right: 0;
                        }
                    }

                    .quantity {
                        margin-top: rem-calc(16);
                    }

                    .see-product-details {
                        margin-top: rem-calc(15);
                        width: 100%;
                        text-align: left;
                        border-top: rem-calc(1) solid $medium-gray;

                        a {
                            font-size: rem-calc(11);
                            font-weight: 400;
                            text-decoration: underline;
                            text-transform: uppercase;
                            line-height: rem-calc(25);

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .attributes {
                    .js-select-size-for-mobile {
                        .attribute-container {
                            .pdp-size-box {
                                margin-top: 0;
                                .attribute {
                                    flex-flow: unset !important;

                                    @include breakpoint (large up) {
                                        margin-top: rem-calc(65);
                                    }
                                }
                                .product-content-size label {
                                    width: auto;
                                    display: flex;
                                    align-items: center;
                                    &.swatch-boolean {
                                        border: rem-calc(1) solid $border-gray;
                                        border-right: rem-calc(1) solid $border-gray !important;
                                        width: auto;
                                        line-height: rem-calc(40);
                                        font-size: rem-calc(16);
                                        letter-spacing: rem-calc(0.4);
                                        padding: rem-calc(8);
                                        &:not(:last-child) {
                                            margin-right: rem-calc(9);
                                        }
                                        .radio-label {
                                            line-height: rem-calc(32);
                                            height: rem-calc(32);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .see-product-details-container {
                    .see-product-border-top {
                        margin-top: rem-calc(25);
                        .see-product-details-link {
                            a {
                                font-size: rem-calc(12);
                                line-height: rem-calc(17);
                                font-weight: 500;
                            }
                        }
                    }
                    .product-details-link-icon {
                        width: 0.75rem;
                        height: rem-calc(9);
                    }
                }

                h4 {
                    &.product-name {
                        font-size: rem-calc(16);
                        line-height: rem-calc(20);
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        font-weight: 400;
                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
            /*
            .product-name-quick {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                font-weight: 400;
                margin: 0;
            }
            */

            .availability.product-availability {
                .availability-msg {
                    font-size: rem-calc(10);
                    line-height: rem-calc(14);
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
            .availability.global-availability {
                margin-bottom: rem-calc(60);
                .availability-msg {
                    font-size: rem-calc(10);
                    line-height: rem-calc(14);
                    font-weight: 400;
                }
            }

            .add-to-cart-global, .update-cart-product-global {
                margin-top: rem-calc(10);
                margin-bottom: rem-calc(10);
                width: 100%;

                &.cc-quickAddModal-addToCart-button {
                    margin: 0;
                }
            }
            .quantity {
                background: gray(4);
                display: flex;
                justify-content: center;
                align-items: center;

                &.cc-quickAddModal-addToCart-wrp {
                    background: $white;
                }
            }

            .wishlistIcon_quickView {
                align-self: center;
                margin: rem-calc(0 0 0 20);
            }

            &.product-freegift {
                .primary-images {
                    @include breakpoint(small down) {
                        max-width: 100% !important;
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                    }
                    .carousel-item {
                        @include breakpoint(small down) {
                            margin-bottom: 0;
                            max-width: none;
                            width: auto;
                            max-height: rem-calc(350);
                        }
                    }
                    .slider-count.modal-bonus-carousel{
                        @include breakpoint (medium down){
                            padding-left: rem-calc(16);
                            position: relative;
                            bottom: rem-calc(29);
                        }
                        p{
                            @include breakpoint (medium down){
                                font-size: rem-calc(14);
                                margin: 0;
                            }
                        }
                    }
                }
                .add-to-cart {
                    @include breakpoint(small down) {
                        margin: 0;
                    }
                }

            }

            .product-color-sales {
                display: none;
            }

            .attributes {
                @include breakpoint (large up) {
                    margin-top: rem-calc(20);
                }
            }
        }

        .edit-product-quickview {
            background-color: $white;
            margin-top: 0;

            .product-details {
                display: flex;

                @include breakpoint (medium down) {
                    flex-direction: column;
                    min-height: 100dvh;
                }

                .miniatureVerticalImg {
                    height: 50vh;
                    top: unset;
                    
                    .slider-quick {
                        width: unset;
                    }
                }
            }

            .title {
                padding: rem-calc(16 40);
                font-size: rem-calc(12);
                font-weight: $global-weight-bold;
                font-family: $secondary-font;

                @include breakpoint (medium up) {
                    background-color: $neutral;
                }
                @include breakpoint (medium down) {
                    font-size: rem-calc(14);
                    padding: rem-calc(16);
                }

                .close-custom {
                    float: right;
                    position: relative;
                    top: rem-calc(2);
                    right: unset;
                    font-size: unset;
                }
            }

            .primary-images {
                max-width: rem-calc(380);
                overflow: hidden;

                @include breakpoint (medium up) {
                    height: rem-calc(532);
                    width: rem-calc(380);
                    flex-grow: 1;
                }

                @include breakpoint (medium down) {
                    max-width: 100dvw;
                    width: 100dvw;
                    height: rem-calc(198);
                }

                .slick-track {
                    margin-left: 0;

                    .carousel-item {
                        margin-bottom: 0;
                        max-width: rem-calc(380);
                        width: auto;
                        height: auto;
                        scroll-snap-align: start;

                        @include breakpoint (medium down) {
                            &:not(:last-child) {
                                margin-right: rem-calc(4);
                            }
                        }
                    }
                }
            }
            .description {
                flex-grow: 1;

                position: relative;
                display: flex;
                flex-direction: column;
                height: auto;

                .quickViewModal {
                    flex-grow: 1;
                    
                    padding-right: rem-calc(40);
                    padding-left: rem-calc(40);

                    @include breakpoint (medium down) {
                        display: flex;
                        padding-right: rem-calc(16);
                        padding-left: rem-calc(16);
                    }
                    
                    .carousel-select-color {
                        @include breakpoint (medium down) {
                            padding-left: rem-calc(5);
                            width: calc(100dvw - #{rem-calc(32)});
                            flex-wrap: nowrap;
                            overflow-y: scroll;
                        }
                        .js-mobile-scroll-color {
                            margin-right: rem-calc(10);
                            padding-top: rem-calc(2);
                            .color-variation {
                                width: rem-calc(30);
                                .selectable-swatches__circle {
                                    height: rem-calc(25);
                                    width: rem-calc(25);
                                    &:before {
                                        width: rem-calc(29);
                                        height: rem-calc(29);
                                    }
                                    &.selected {
                                        &:after {
                                            width: rem-calc(25);
                                            height: rem-calc(25);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .attribute-container {
                        &.promo-message-pdp {
                            margin-top: rem-calc(20);
                        }

                        @include breakpoint (medium down) {
                            padding-left: 0;
                        }
                    }

                    .m-d-price-container {
                        margin-top: rem-calc(15);
                        margin-bottom: rem-calc(10);
                        .price {
                            font-size: rem-calc(20);
                            line-height: rem-calc(20);
                            .strike-through {
                                .value {
                                    font-size: rem-calc(18);
                                }
                            }
                        }
                    }

                    .wishlist-icon {
                        display: block;
                        right: rem-calc(9.8);
                    }
                    .attribute-container.quantity.container-flex-desktop-quantity-message {
                        display: flex;
                        flex-flow: row wrap;
                    }
                }

                .product-name-container {
                    margin: rem-calc(24 40);

                    @include breakpoint (medium down) {
                        margin: rem-calc(24 16 32 16);
                        padding-bottom: rem-calc(32);
                        border-bottom: rem-calc(1) solid $neutral;
                    }

                    .price {
                        margin-top: rem-calc(8);
                    }

                    .sales-price {
                        font-size: rem-calc(18);
                        font-weight: $global-weight-bold;

                        @include breakpoint (medium down) {
                            font-size: rem-calc(16);
                        }
                    }

                    .list-price {
                        font-size: rem-calc(16);
                        font-weight: $global-weight-bold;
                        text-decoration: line-through;
                        color: $light-gray;
                    }

                    .discount-value {
                        font-size: rem-calc(16);
                        font-weight: $global-weight-bold;
                        font-family: $secondary-font;

                        @include breakpoint (medium down) {
                            font-size: rem-calc(14);
                        }
                    }
                    .cc-omnibusStack {
                        @include breakpoint (medium down) {
                            margin-top: rem-calc(8);
                        }
                    }
                }

                &.add-freegift {
                    @include breakpoint(small down) {
                        background-color: $white;
                        z-index: 998;
                        margin-left: 0;
                        width: 100vw !important;
                        top: initial;
                        margin-top: 0;
                        padding-top: rem-calc(11);
                        @supports (padding-bottom: env(safe-area-inset-bottom)) {
                            padding-bottom: env(safe-area-inset-bottom);
                        }
                    }
                    .m-d-price-container {
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                        font-weight: 600;
                        margin-top: rem-calc(8);
                        margin-bottom: 0;
                        @include breakpoint(small down) {
                            padding: rem-calc(0 45 4 18);
                        }
                        &.bonus-price-container{
                            margin-bottom: rem-calc(20);
                        }
                    }
                    .modal-quick-size-quantity {
                        padding-top: rem-calc(20);
                        align-items: flex-start;
                        @include breakpoint(small down) {
                            position: fixed;
                            bottom: 3.5rem;
                            left: 0;
                            z-index: 2;
                            margin-left: 0;
                            width: 100vw !important;
                            top: initial;
                        }
                        &.bonus-product-modal-container{
                            align-items: unset;
                        }
                    }

                    h4.product-name {
                        height: auto;
                        height: auto;
                        @include breakpoint(small down) {
                            padding: rem-calc(12 45 4 18);
                            font-size: rem-calc(14);
                            min-height: initial;
                        }
                        a {
                            font-weight: 400;
                            padding-bottom: rem-calc(8);
                            font-size: rem-calc(20);
                            line-height: rem-calc(26);
                        }
                    }

                }
                .carousel-select-size {
                    .product-content-size {
                        margin-top: rem-calc(20);
                    }
                }
                .description {
                    position: relative;
                    .modal-quick-size-quantity {
                        position: absolute;
                        background-color: $white;
                        border: rem-calc(1) solid $border-gray;
                        bottom: rem-calc(-20);
                        padding: rem-calc(15 24);
                        .pdp-size-box {
                            margin-top: 0;
                        }
                        button.close-select-size {
                            margin-right: 0;
                        }
                    }

                    .quantity {
                        margin-top: rem-calc(16);
                    }
                }

                .attributes {
                    .js-pickable-content-mobile-container-v {
                        flex-grow: 1;
                        .color-size-picker {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .js-select-size-for-mobile {
                                .attribute-container {
                                    .pdp-size-box {
                                        margin-top: 0;
                                        .attribute {
                                            flex-flow: unset !important;

                                            .drop-down-size-selector {
                                                position: relative;

                                                .arrow {
                                                    position: initial;
                                                    margin: rem-calc(0 16);
                                                }
                                                .js-size-panel {
                                                    position: absolute;
                                                    transform: translateY(-100%);
                                                    line-height: rem-calc(46);
                                                    max-height: rem-calc(234);
                                                    overflow-y: auto;
                                                }

                                                .data-a {
                                                    margin-right: rem-calc(2);
                                                    width: unset;
                                                    font-family: $secondary-font;

                                                    .data-container {
                                                        justify-content: space-between;

                                                        .size-display {
                                                            margin-left: rem-calc(12);
                                                        }
                                                    }
                                                }

                                                .bell {
                                                    margin-right: initial;
                                                }
                                            }
                                        }
                                        .product-content-size label {
                                            width: auto;
                                            display: flex;
                                            align-items: center;
                                            &.swatch-boolean {
                                                border: rem-calc(1) solid $border-gray;
                                                border-right: rem-calc(1) solid $border-gray !important;
                                                width: auto;
                                                line-height: rem-calc(40);
                                                font-size: rem-calc(16);
                                                letter-spacing: rem-calc(0.4);
                                                padding: rem-calc(8);
                                                &:not(:last-child) {
                                                    margin-right: rem-calc(9);
                                                }
                                                .radio-label {
                                                    line-height: rem-calc(32);
                                                    height: rem-calc(32);
                                                }
                                            }
                                        }
                                    }
                                    .product-sizes {
                                        .selected-size {
                                            .label {
                                                margin-right: rem-calc(6);
                                            }

                                            .value {
                                                font-family: $secondary-font;
                                            }
                                        }
                                        .size-guide {
                                            float: right;
                                            .linkModalFindYourSize {
                                                border-bottom-color: $outrageous-orange;
                                            }
                                        }

                                        .list {
                                            display: grid;
                                            grid-template-columns: repeat(3, calc((100% - #{rem-calc(16)}) / 3));
                                            gap: rem-calc(8);

                                            margin-top: rem-calc(24);
                                            margin-bottom: rem-calc(32);
    
                                            .boolean-field {
                                                height: rem-calc(40);
                                                padding-right: 0;
    
                                                .select-label {
                                                    height: rem-calc(40);
                                                    border: rem-calc(1) solid $neutral;

                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    font-family: $secondary-font;
                                                    padding: rem-calc(0 4);

                                                    .size-value {
                                                        font-weight: initial;
                                                    }

                                                    &.selected:not(.notifyMe) {
                                                        border-color: $black;
    
                                                        .size-value {
                                                            font-weight: $global-weight-bold;
                                                        }
                                                    }
                                                    
                                                    &.notifyMe {
                                                        border-color: $light-gray;
                                                        .size-value {
                                                            color: $light-gray;
                                                            text-decoration: line-through;
                                                        }
                                                    }
                                                }

                                                &:only-child {
                                                    min-width: min-content;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .js-select-color-for-mobile {
                                @include breakpoint (medium down) {
                                    padding-bottom: rem-calc(12);
                                    border-bottom: rem-calc(1) solid $neutral;
                                    margin-bottom: rem-calc(32);
                                }
                                .colorText {
                                    font-size: rem-calc(14);
                                    text-transform: lowercase;
                                    
                                    display: inline-block;
                                    &::first-letter {
                                        text-transform: uppercase;
                                    }
                                }
                            }

                        }
                    }
                }

                h4 {
                    &.product-name {
                        font-size: rem-calc(16);
                        line-height: rem-calc(20);
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        font-weight: 400;
                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
            /*
            .product-name-quick {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                font-weight: 400;
                margin: 0;
            }
            */

            .availability.product-availability {
                .availability-msg {
                    font-size: rem-calc(10);
                    line-height: rem-calc(14);
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
            .availability.global-availability {
                margin-bottom: rem-calc(60);
                .availability-msg {
                    font-size: rem-calc(10);
                    line-height: rem-calc(14);
                    font-weight: 400;
                }
            }

            .add-to-cart-global, .update-cart-product-global {
                margin-top: 0;
                margin-bottom: rem-calc(40);
                width: 100%;

                @include breakpoint (medium down) {
                    margin-bottom: 0;
                }
            }
            .quantity {
                background: gray(4);
                display: flex;
                justify-content: center;
                align-items: center;

                @include breakpoint (medium down) {
                    height: rem-calc(40);
                    border: rem-calc(1) solid $neutral;
                    background-color: $white;

                    .quantityButtons {
                        height: 100%;
                    }
                }
            }

            .update-product {
                @include breakpoint (medium down) {
                    display: grid;
                    grid-template-columns: 37% auto;
                    column-gap: rem-calc(8);
                    padding-top: rem-calc(16);
                    padding-bottom: rem-calc(16);
                    border-top: rem-calc(1) solid $neutral;

                    .cart-and-ipay {
                        padding: 0;
                    }
                }
            }

            .wishlistIcon_quickView {
                align-self: center;
                margin: rem-calc(0 0 0 20);
            }

            &.product-freegift {
                .primary-images {
                    @include breakpoint(small down) {
                        max-width: 100% !important;
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                    }
                    .carousel-item {
                        @include breakpoint(small down) {
                            margin-bottom: 0;
                            max-width: none;
                            width: auto;
                            max-height: rem-calc(350);
                        }
                    }
                }
                .add-to-cart {
                    @include breakpoint(small down) {
                        margin: 0;
                    }
                }

            }

            .product-color-sales {
                display: block;
                margin-bottom: rem-calc(12);
            }

            .attributes {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include breakpoint (large up) {
                    height: 100%;
                    margin-top: 0;
                }

                @include breakpoint (medium down) {
                    height: auto;
                }
            }
        }
    }

    & .marketing-messages {
        margin-left: rem-calc(0);
        font-size: rem-calc(14);
        & .callout {
            display: inline-block;
        }
    }

    .prices {
        .value {
            font-size: rem-calc(20);
            line-height: rem-calc(26);
            @include breakpoint (medium down) {
                font-size: rem-calc(16);
                line-height: rem-calc(22);
            }
        }
    }

    .add-to-wishlist {
        display: inline-block;
        margin-left: rem-calc(16);

        &:hover {
            color: $primary;
        }
    }
}

.quickview-modal-edit-cart-product {
    .tingle-modal-box {
        background-color: white;
        @include breakpoint (medium down) {
            min-height: 100dvh;
            width: 100dvw;
        }
    }
}

a {
    &.added {
        color: $primary;
    }
    /*
    &.find-the-size {
        font-size: rem-calc(11);
        font-weight: 400;
        text-decoration: underline;
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
        }
    }
    */
}

/*
.button-padding-right5 {
    padding-right: rem-calc(5);
}
*/
/*
.button-padding-left5 {
    padding-left: rem-calc(5);
}
*/
/*
.button-bag-full-width {
    width: 100%;
    margin-right: 0;
}
*/

.slider-quick-modal {
    button {
        &.slick-next {
            @include breakpoint (medium down){
                display: none!important;
            }
            &::before {
                content: url('../images/quick-arrow-new.svg');
            }
        }
        &.slick-prev {
            @include breakpoint (medium down){
                display: none!important;
            }
            &::before {
                content: url('../images/quick-arrow-prev-new.svg');
            }
        }
    }
}

.action-bar {
    height: rem-calc(40);
    min-height: rem-calc(40);
    line-height: rem-calc(36);

    .data-container {
        width: 100%;
        height: 100%;
        position: relative;
        margin: 0;
        padding: 0;
        font-size: rem-calc(11);
        .data-container-text{
            padding-left: rem-calc(24);
            padding-right: rem-calc(24);
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .size-display {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(20);
            font-family: $secondary-font;

            @include breakpoint (large up) {
                position: relative;
                top: rem-calc(3);
            }
        }
    }

    .arrow {
        position: absolute;
        top: rem-calc(0);
        right: rem-calc(30);

        @include breakpoint (large up) {
            top: rem-calc(2);
        }
        svg {
            max-height: rem-calc(7);
        }
    }
}