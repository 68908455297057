.bonus-product-modal {
    .modal-header {
        display: none;
    }

    .tingle-modal-box {
        @include breakpoint(small down) {
            width: 100vw;
            max-height: 100vh;
            margin: 0;
        }
    }

    .quantity-number-box {
        opacity: 0.5;
    }

    .quantity-button:disabled {
        color: $black;
        opacity: 0.5;
    }

    @include breakpoint(medium down) {
        .modal-quick-size-quantity {
            display: none;
            &.open {
                display: block;
                background-color: transparent;
            }
        }
        .attribute-container {
            padding: rem-calc(0 16 0 16);
        }
    }

    .color-variation {
        .selectable-swatches__circle {
            &.selected {
                outline: rem-calc(2) solid $black;
                outline-offset: rem-calc(5);
                
                &::after {
                    content: unset;
                }
            }
        }
    }
}
.cart-and-ipay {
    padding-top: rem-calc(16);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
}
