$include-fonts: false;

@import '../setup/settings';
@import '../mixins/helpers';
@import 'plp/quickview';
@import 'plp/tile';
@import 'pdp/productLocator';
@import '../plugins/scalapay';
@import 'pdp/ratingsReviews';
@import 'pdp/sizeModal';

#esw-fab.ui-eswback {
    z-index: 800 !important;
}

/* Used to improve CLS */
@include breakpoint(small down) {
    .loading .product-detail .cell .breadcrumb {
        display: none;
    }
}

.wishlist-icon {
    @include breakpoint(small down) {
        padding-left: rem-calc(6);
    }
}

.categoty-banner-container {
    img {
        width: 100%;
    }
    .banner-text {
        position: absolute;
        max-width: rem-calc(400);
        .title-banner {
            font-size: rem-calc(63);
            line-height: rem-calc(70);
            @include breakpoint(medium down) {
                font-size: rem-calc(20);
                line-height: rem-calc(27);
                margin-bottom: rem-calc(6);
            }
        }
        .subtitle-banner {
            font-size: rem-calc(25);
            line-height: rem-calc(14);
            @include breakpoint(medium down) {
                font-size: rem-calc(12);
            }
        }
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@include breakpoint(small down) {
    .bonus-product-modal {
        & .tingle-modal-box {
            min-height: 100%;
            height: 100%;
        }
    }
}

@include breakpoint(medium up) {
    .page > .grid-container .add-to-cart-messages {
        display: none;
    }
}

@include breakpoint(small down) {
    .main-header-wrap .add-to-cart-messages {
        display: none;
    }

    .internal-message .add-to-cart-messages {
        top: 0;
        z-index: 3;
        width: 80vw;
        position: fixed;
    }

    .page > .grid-container.affix-top-for-small-only .add-to-cart-messages {
        position: fixed;
        max-width: 70vw;
        right: rem-calc(20);
        display: block !important;
    }

    .pdp-title {
        font-weight: 400;
    }
}

.divider-size {
    border-bottom: 1px solid $border-gray;
    margin-bottom: rem-calc(14);
    @include breakpoint(medium down) {
        border: 0;
    }
}

.product-info-sidebar {
    @include breakpoint(medium down) {
        .mood-container {
            order: -1;
        }
        .color-container {
            order: -1;
        }

        .actions-container {
            font-size: rem-calc(14);
            padding-left: rem-calc(16.5);
            padding-right: rem-calc(16.5);
            margin-top: rem-calc(12);
            padding-top: rem-calc(10);
        }
    }
}

.performance_info,
.mood_info {
    font-size: rem-calc(11);
    font-weight: 600;
}
.product-mood-container,
.product-performance-container {
    .arrow-right-pdp {
        width: 0.5rem;
        height: 0.5rem;
    }
}
.font-size-12 {
    font-size: rem-calc(12);
}
.font-size-13 {
    font-size: rem-calc(13);
    font-weight: 500;
}
.font-size-14 {
    font-size: rem-calc(14);
}

.m-d-price-container {
    text-align: right;

    @include breakpoint(medium up) {
        text-align: left;
        font-size: rem-calc(18);
    }

    @include breakpoint(medium down) {
        text-align: left;
        margin-top: rem-calc(10);
    }

    .cc-omnibusStack {
        margin: rem-calc(4 0 9 0);

        @include breakpoint(large up) {
            margin: rem-calc(8 0 1 0);
        }
    }

    .price {
        display: inline;
        font-size: rem-calc(16);
        color: black;
        height: rem-calc(26);
        .sales {
            line-height: rem-calc(26);
        }
    }
    .strike-through {
        display: inline-block;
        font-weight: 600;
        color: $light-gray;
        font-size: rem-calc(20);
        line-height: rem-calc(26);
        margin-right: rem-calc(8);
    }
}

.cleanslate .hide-widget .SGBasket-widget {
    z-index: 998 !important;
}

.price-second-currency {
    min-height: rem-calc(28);
    margin-top: rem-calc(8);
}
.double-currency-sales {
    line-height: rem-calc(22);
    font-weight: 400;
}

.double-currency-list.strike-through {
    font-weight: 400;
    margin-left: rem-calc(8);
}
.page[data-action='Search-Show'] {
    .price-second-currency {
        height: rem-calc(18);
        min-height: unset;
    }
}
.page[data-action='Product-Show'] {
    .double-currency-sales,
    .double-currency-list.strike-through {
        font-size: rem-calc(16);
    }
}

.product-code {
    padding: rem-calc(0 16.5);
    font-size: rem-calc(10);
    color: $light-gray;
    @include breakpoint(large up) {
        padding: 0;
    }
}

.promo-message {
    font-size: rem-calc(13);
}

#crossSellContainer {
    @include breakpoint(medium down) {
        .grid-item {
            max-width: inherit !important;
            min-height: inherit;
        }
    }
    & .slick-slide {
        float: left;
        margin-right: 0;
        margin-left: rem-calc(30);

        @include breakpoint(small down) {
            margin-right: rem-calc(0);
            margin-left: rem-calc(0);

            .product {
                margin: rem-calc(0 5);
            }
        }
    }
}

.description-and-detail {
    & .content {
        width: 100%;
        min-height: rem-calc(80);
        padding: rem-calc(5);

        .icon-img {
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }

    h4 {
        font-weight: 500;
        margin-top: rem-calc(25);
    }

    .details {
        h6 {
            opacity: 0.5;
        }
    }

    .accordion {
        @include breakpoint(small down) {
            margin-top: rem-calc(18);
        }

        .accordion-item {
            .accordion-title {
                margin-top: rem-calc(31);
                font-weight: 600;

                @include breakpoint(small down) {
                    margin-top: rem-calc(0);
                    font-size: rem-calc(14);
                }
            }
        }

        &.initialized {
            .accordion-item {
                .accordion-title {
                    margin: rem-calc(0 10);
                    padding: rem-calc(17 0);
                    position: relative;
                    border-top: rem-calc(1) solid $border-gray;
                    border-bottom: 0;
                    cursor: pointer;

                    &::after {
                        right: 0;
                    }
                }

                &:last-child {
                    .accordion-title {
                        border-bottom: rem-calc(1) solid $border-gray;
                    }

                    .accordion-content {
                        border-bottom: rem-calc(1) solid $border-gray;
                    }
                }

                &.active {
                    .accordion-title {
                        border-bottom: 0;
                    }

                    .accordion-content {
                        border-bottom: 0;
                    }

                    &:first-child {
                        .accordion-content {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }

        .accordion-content {
            @include breakpoint(small down) {
                padding: rem-calc(17 0);
                margin: 0 rem-calc(10);
                border-bottom: 0;
            }

            .container-tabs-wash {
                margin-top: rem-calc(20);

                ul.tabs {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }

                ul.tabs li {
                    background: none;
                    color: $black;
                    display: inline-block;
                    border-bottom: rem-calc(1) solid $medium-gray;
                    border-top: rem-calc(1) solid $medium-gray;
                    border-right: rem-calc(1) solid $medium-gray;
                    border-left: rem-calc(1) solid $medium-gray;
                    padding: rem-calc(5 10 8 10);
                    cursor: pointer;
                    margin-right: rem-calc(5);
                    margin-bottom: rem-calc(10);

                    @include breakpoint(small down) {
                        margin-right: rem-calc(0);
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                ul.tabs li.current {
                    background: none;
                    color: $black;
                    border-bottom: rem-calc(4) solid $black;
                    border-top: rem-calc(1) solid $medium-gray;
                    border-right: rem-calc(1) solid $medium-gray;
                    border-left: rem-calc(1) solid $medium-gray;
                    padding: rem-calc(5 10);
                }

                .tab-content {
                    display: none;
                    background: none;
                    padding: rem-calc(15 0);
                }

                .tab-content.current {
                    display: inherit;
                }
            }
        }
    }
}

.modalGetProductInfo {
    .accordion-content {
        @include breakpoint(small down) {
            padding: rem-calc(17 0);
            margin: 0 rem-calc(10);
            border-bottom: 0;
        }

        .container-tabs-wash {
            margin-top: rem-calc(16);

            ul.tabs {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            ul.tabs li {
                height: rem-calc(40);
                width: rem-calc(40);
                background: none;
                color: $black;
                display: inline-block;
                border-bottom: rem-calc(1) solid $medium-gray;
                border-top: rem-calc(1) solid $medium-gray;
                border-right: rem-calc(1) solid $medium-gray;
                border-left: rem-calc(1) solid $medium-gray;
                padding: rem-calc(5 10 8 10);
                cursor: pointer;
                margin-right: rem-calc(5);
                margin-bottom: rem-calc(10);

                @include breakpoint(small down) {
                    margin-right: rem-calc(0);
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            ul.tabs li.current {
                background: none;
                color: $black;
                border-bottom: rem-calc(4) solid $black;
                border-top: rem-calc(1) solid $medium-gray;
                border-right: rem-calc(1) solid $medium-gray;
                border-left: rem-calc(1) solid $medium-gray;
                padding: rem-calc(5 10);
            }

            .tab-content {
                display: none;
                background: none;
                padding: rem-calc(15 0);
            }

            .tab-content.current {
                display: inherit;
            }
        }
    }
}

.js-panel-opener.open .caret {
    transform: rotate(-180deg);
}

.size-interactive-guide {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: rem-calc(10);

    & .size-guide-label {
        margin-top: auto;
        margin-bottom: auto;
    }

    & .size-guide-select {
        border: 0;
        border-bottom: rem-calc(1) solid $black;
        display: inline-block;
    }
}

.small-triangle-down {
    @include css-triangle(rem-calc(6), $black, 'down');
    margin-top: rem-calc(10);
    margin-right: rem-calc(7);
}

.color-variation {
    display: inline-block;
    position: relative;
    margin: 0;
    display: inline-block;
    font-size: 0;
    z-index: 0;

    & .swatch-circle {
        vertical-align: super;
    }

    & .on-sale-product {
        &.selectable-swatches__circle:before {
            background-color: $orange-red;
        }
    }

    & .selectable-swatches__circle.unselectable {
        cursor: not-allowed;
        opacity: 0.2;
    }
    .selectable-swatches__circle {
        @include breakpoint(medium down) {
            margin-top: rem-calc(5);
        }
        &.selected::after {
            content: '';
            border: 1px solid black;
            transform: scale(1.5);
            width: rem-calc(24);
            height: rem-calc(24);
            border-radius: 50%;
            display: inherit;
            background-color: transparent;
        }
    }
}

#menuSelectBarMobile {
    .price-mobile-bottom {
        margin-bottom: 0;
        height: rem-calc(40);
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: $white;
        &.bonus-price {
            @include breakpoint(medium down) {
                padding: rem-calc(0 16);
                display: block;
                height: unset;
                margin-bottom: rem-calc(16);
                border-bottom: 1px solid $neutral;
                padding-bottom: rem-calc(8);
            }
        }
    }

    .m-d-price-container {
        @include breakpoint(medium down) {
            text-align: left;
            margin-top: 0;
        }
    }
}
.add-bonus-products-wrapper {
    @include breakpoint(medium down) {
        padding: rem-calc(0 16 16);
    }
}

.font-size-22-desk {
    @include breakpoint(large up) {
        font-size: rem-calc(20);
    }
    padding-right: rem-calc(8);
    color: black;
    font-size: rem-calc(16);
    font-weight: 600;
    line-height: rem-calc(26);
    text-transform: none;
}
.display-inline-block {
    display: inline-block;
}

.container-flex-desktop-quantity-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.product-content-size {
    display: block;
    width: 100%;
    float: left;

    label {
        width: auto;
        display: flex;
        align-items: center;

        &.swatch-boolean {
            padding: rem-calc(6 8);
            float: left;
            border: rem-calc(1) solid $medium-gray;
            height: rem-calc(32);
            margin-bottom: rem-calc(10);
            min-width: rem-calc(32);
            justify-content: center;
            &:not(:last-child) {
                border-right: 0;
            }

            .radio-label {
                line-height: rem-calc(0);
                font-size: rem-calc(14);

                @include breakpoint(small down) {
                    line-height: rem-calc(0);
                }
            }

            @include breakpoint(small down) {
                border: rem-calc(1) solid $border-gray;
                border-right: rem-calc(1) solid $border-gray !important;
                width: auto;
                line-height: rem-calc(40);
                font-size: rem-calc(16);
                letter-spacing: rem-calc(0.4);
                padding: rem-calc(8 14);
                &:not(:last-child) {
                    margin-right: rem-calc(14);
                }
            }

            & input[type='radio']:checked + .radio-label {
                text-decoration: none;
                font-weight: 400;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: rem-calc(0);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    border-radius: 0;
                    border-bottom: rem-calc(2) solid $black;
                }
            }

            .notifyMe {
                color: gray;
                @include breakpoint(medium up) {
                    display: flex;
                    align-items: center;
                }
                svg {
                    &.alertNotify {
                        display: inline;
                    }
                }
            }
            .alertNotify {
                display: none;
            }
        }
    }

    hr {
        width: 100%;
        margin: 0;
    }
}

.quantity-value {
    display: inline;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: rem-calc(14);
    font-weight: 600;
}

.quantity-number-box {
    display: inline-flex;
    height: rem-calc(40);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: rem-calc(0 24);
    @include breakpoint(medium down) {
        width: 2.78571rem;
        margin: rem-calc(0 8);
    }
}

.margin-grid {
    @include breakpoint(medium down) {
        margin-bottom: rem-calc(15);
    }
    @include breakpoint(medium down) {
        margin-bottom: 0;
    }
}

.attribute-container {
    @include breakpoint(medium down) {
        background: $white;
        z-index: 2;
        bottom: rem-calc(40);
        padding-left: rem-calc(16.5);

        &.quantity {
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(25);
        }
    }

    & .accordion-panel-group {
        padding: rem-calc(10 0);

        @include breakpoint(small down) {
            border: 0;
            border-top: 0;
            padding: rem-calc(10);
        }

        hr {
            margin: rem-calc(2 0);
        }
    }

    &.promo-message-pdp {
        @include breakpoint(medium up) {
            margin-top: rem-calc(52);
        }
        .promo-info-icon {
            @include breakpoint(small down) {
                position: absolute;
                right: rem-calc(3);
                top: rem-calc(2);
            }
        }
    }

    .pdp-size-box {
        margin-top: rem-calc(16);

        @include breakpoint(small down) {
            margin-top: rem-calc(0);
            h4 {
                margin-bottom: rem-calc(22);
            }
        }

        .close-select-size {
            @include breakpoint(small down) {
                font-size: rem-calc(19);
                position: absolute;
                top: rem-calc(15);
                right: rem-calc(15);
                height: rem-calc(24);
                width: rem-calc(19);
                i {
                    svg {
                        width: rem-calc(16);
                        height: rem-calc(16);
                    }
                }
            }
        }
    }
}

.mini-image-container {
    position: relative;
    height: auto;
    @include breakpoint(medium up) {
        padding-bottom: rem-calc(8);
    }

    &:last-child {
        padding-bottom: 0;
    }
    &:hover {
        cursor: pointer;
    }
    &.selected {
        opacity: 0.6;
        &:hover {
            cursor: default;
        }
    }
}

.product-detail.product-detail-keeper {
    .image-gallery-container {
        #imageSection {
            position: relative;
            #gallerySlideCounter {
                z-index: 1;
                font-size: rem-calc(12);
                .current-slide {
                    font-weight: bold;
                }
                position: absolute;
                bottom: 0;
                right: 0;
                margin-bottom: rem-calc(10);
                left: rem-calc(18);
                &.last-slide {
                    right: 0;
                }
            }
        }
    }
    .sidebar-container {
        @include breakpoint(medium up) {
            width: 37.5549048vw;
            padding-left: rem-calc(40);
        }
    }
    .ha-slider-prodotti.complete {
        @include breakpoint(medium down) {
            padding-top: rem-calc(22);
            padding-right: 0;
            margin-top: rem-calc(40);
        }
    }
}

.up-arrow-container,
.down-arrow-container {
    position: absolute;
    z-index: 2;
    &:hover {
        cursor: pointer;
    }
    @include breakpoint(medium down) {
        display: none;
    }
    &.disabled {
        filter: opacity(0.5);
    }
}

.large-image-container {
    @include breakpoint(medium up) {
        margin-left: calc(10.9088599vw + 8px);
        width: 45.0951684vw;
    }
    @include breakpoint(medium down) {
        .slick-list {
            margin: rem-calc(0 -5);
        }
        .image-wrapper {
            margin: rem-calc(0 5);
        }
    }
    .large-image {
        @include breakpoint(large up) {
            &:hover {
                cursor: zoom-in;
            }
        }
    }
    &.world-site {
        @include breakpoint(medium up) {
            width: unset;
        }
    }
    &.js-quickview-product-carousel {
        @include breakpoint(medium up) {
            margin-left: 0;
            width: auto;
            padding-left: 0;
        }
    }
}

#miniatureVerticalImgContainer {
    overflow: scroll;
    height: 100%;
    z-index: 1;
    @include breakpoint(medium up) {
        width: 10.9088599vw;
        position: absolute;
    }
    .images-root {
        position: relative;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    &.world-site {
        overflow: scroll;
        height: unset;
        z-index: 1;
    }
}

.product-breadcrumb {
    margin-bottom: rem-calc(10);
}

.breadcrumb-arrow-desktop {
    margin-left: 0 !important;
    margin-right: rem-calc(8);
    .arrow {
        width: rem-calc(12);
        height: rem-calc(9);
        margin-right: 0;
    }
}

.product-tile {
    & .quickview-icon {
        display: none;
    }
}
#crossSellContainer {
    .image-container {
        position: relative;
        @include breakpoint(medium up) {
            width: 18.96046852122987vw;
        }
    }
}

.crosssell-view {
    & .product-tile {
        &__name {
            @include breakpoint(medium down) {
                max-width: 100%;
            }
            .pdp-link {
                height: unset;
                width: 100%;

                @include breakpoint(medium down) {
                    .link {
                        white-space: nowrap;
                        max-width: 100%;
                        display: inline-block;
                    }
                }
                margin-top: rem-calc(20);
            }
            .new-message {
                display: none;
            }
        }
        &__promo-message {
            display: none;
        }
        .tile-image {
            &.back {
                display: none;
            }
            &.front {
                display: inline-block;
            }
        }
    }
    & .tile-colors {
        display: none;
    }
    :focus-visible {
        outline: none;
    }
}

@include breakpoint(small down) {
    .mobile-container {
        width: 100%;
    }
}

.js-pickable-content-mobile-container {
    @include breakpoint(small down) {
        width: 100%;
        background: white;
        z-index: 0;

        .js-select-size-for-mobile,
        .js-select-color-for-mobile {
            &.open {
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(10);
            }
        }
    }
}

.small-margin-cell {
    @include breakpoint(medium down) {
        margin: rem-calc(0 8);
    }
}

.cart-and-ipay {
    @include breakpoint(medium down) {
        margin-top: rem-calc(20);
    }
    @include breakpoint(small down) {
        margin-top: 0;
        padding: 0.2rem 0;
        & .find-in-store-btn {
            color: #000;
        }
    }
}

.findYourSizeModal {
    z-index: 2053;
}
.delivery-modal {
    .bold {
        font-weight: bold;
    }
    &.pdp-modal {
        @include breakpoint(medium up) {
            width: rem-calc(780);
            max-width: rem-calc(780);
        }
    }
    .modalDeliveryReturn {
        @include breakpoint(medium up) {
            width: 100%;
        }
        .pdp-body-container {
            .pdp-body {
                @include breakpoint(medium down) {
                    margin-top: rem-calc(40);
                }
            }
            .pdp-body-description {
                margin-bottom: rem-calc(16);
            }
            .pdp-title {
                margin-bottom: rem-calc(16);
                &.smaller-margin-mobile {
                    @include breakpoint(medium down) {
                        margin-bottom: rem-calc(8);
                    }
                }
            }
            .mid-section {
                margin-top: rem-calc(40);
                margin-bottom: rem-calc(12);
                @include breakpoint(medium down) {
                    margin-top: rem-calc(32);
                }
                .pdp-title {
                    margin-top: rem-calc(40);
                    margin-bottom: rem-calc(16);
                    @include breakpoint(medium down) {
                        margin: 0;
                        margin-bottom: 0.57143rem;
                    }
                }
            }
            .footer-section {
                .pdp-title {
                    margin-top: rem-calc(40);
                    @include breakpoint(medium down) {
                        margin-top: rem-calc(32);
                        margin-bottom: rem-calc(8);
                    }
                }
            }
        }
    }
}

.delivery-return-section {
    background-color: $neutral;
    padding: rem-calc(26 27);
    font-size: rem-calc(12);
    margin: rem-calc(0 0 16 0);
    width: 100%;
    .delivery-section,
    .return-section {
        display: flex;
    }

    .delivery-info,
    .return-info {
        font-size: rem-calc(12);
        margin-bottom: rem-calc(14);
        margin-left: rem-calc(12);
        line-height: rem-calc(18);
    }
    .delivery-icon-wrapper,
    .return-icon-wrapper {
        margin-top: rem-calc(3);
    }
    .delivery-icon,
    .return-icon {
        width: rem-calc(17);
        float: left;
    }
    .return-info {
        margin-bottom: 0;
        line-height: unset;
    }
    .link {
        border-bottom: 1px solid black;
        font-size: rem-calc(12);
        font-weight: 600;
        line-height: rem-calc(18);
        margin-right: rem-calc(8);
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        &:hover {
            cursor: pointer;
            color: $dark-gray;
        }
    }
    @include breakpoint(medium down) {
        padding: rem-calc(27 22);
    }
}

.delivery-table {
    width: 100%;
    border-spacing: 0;
    border: 0;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    tr {
        border: 0;
        th,
        td {
            font-weight: normal;
            width: calc(100% / 7);
            text-align: left;
            &.size-legend {
                background-color: $light-over-gray;
            }

            &.size-active {
                background-color: $black;
                color: $white;
            }

            &.size-same {
                background-color: $medium-gray;
            }
            min-width: rem-calc(100);
            border-bottom: 1px solid gray(6);
            border-right: 0;
            border-left: 0;
            padding: rem-calc(16 0);
            @include breakpoint(medium down) {
                padding: rem-calc(16 10);
            }
        }

        th {
            border-top: 0;
            &:nth-child(2) {
                width: 30%;
            }
        }
    }
}

.pdp-modal {
    max-width: 100%;
    position: relative;
    @include breakpoint(medium down) {
        max-height: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: gray(7);
    }
    .tingle-modal-box__content {
        padding: 0;
        .modal__closeIconCalzedonia {
            @include breakpoint(small down) {
                position: relative;
            }
            position: absolute;
            right: 0;
            margin-top: rem-calc(24);
            margin-right: rem-calc(24);
        }
        .pdp-title {
            font-size: rem-calc(16);
            line-height: 1;
            margin-top: rem-calc(28);
            text-transform: uppercase;
            margin-bottom: rem-calc(14);
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(16);
            }
        }
        .pdp-body-title {
            display: block;
            letter-spacing: 0;
            color: gray(13);
            font-weight: medium;
            font-style: normal;
            font-style: rem-calc(16);
            margin-bottom: rem-calc(16);
        }
        .pdp-body-description {
            display: block;
            text-align: left;
            color: gray(12);
            font-size: rem-calc(14);
            margin-bottom: rem-calc(30);
            .block {
                display: block;
            }
            .margin-bottom {
                margin-bottom: rem-calc(16);
            }
            .color-purple {
                color: $loyalty;
            }
        }
        .pdp-body-list {
            margin-left: 0;
            &-item {
                text-align: left;
                letter-spacing: 0.28px;
                margin-bottom: rem-calc(12);
                list-style: none;
            }
        }
        .pdp-table-container {
            padding: rem-calc(24 32);
            margin-bottom: rem-calc(48);
            background-color: #f9f9f9;
            overflow: scroll;
            .pdp-table {
                border-spacing: 0;
                border: 0;
                width: 100%;
                text-align: left;
                thead {
                    border-bottom: 1px solid gray(5);
                }
                tr {
                    border: 0;
                    th,
                    td {
                        &.bold {
                            font-weight: bold;
                        }
                        font-weight: normal;
                        width: calc(100% / 7);
                        text-align: left;
                        border-bottom: rem-calc(1) solid transparent;
                        &.size-legend {
                            background-color: $light-over-gray;
                        }

                        &.size-active {
                            background-color: $black;
                            color: $white;
                        }

                        &.size-same {
                            background-color: $medium-gray;
                        }
                        min-width: rem-calc(100);
                    }

                    th {
                        line-height: rem-calc(22);
                        border: 0;
                        border-bottom: 1px solid gray(5);
                    }

                    td {
                        line-height: rem-calc(38);
                        border: 0;
                    }
                }
            }
        }
        .pdp-section {
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: rem-calc(30);
        }
        .pdp-body-link {
            text-decoration: underline;
        }
        .pdp-body-text {
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.28);
            color: gray(12);
        }
        .pdp-nav-container {
            @include breakpoint(medium down) {
                background-color: gray(7);
            }
            .pdp-nav {
                padding-left: rem-calc(30);
                @include breakpoint(medium down) {
                    padding-left: rem-calc(20);
                }
                .pdp-categories {
                    .pdp-category-link {
                        display: block;
                        padding-bottom: rem-calc(5);
                        color: $medium-gray;
                        &.active {
                            color: gray(13);
                        }
                        @include breakpoint(medium down) {
                            display: inline-block;
                            padding-right: rem-calc(24);
                            padding-bottom: rem-calc(26);
                        }
                    }
                    @include breakpoint(medium down) {
                        overflow-x: scroll;
                        white-space: nowrap;
                    }
                }
            }
        }
        .pdp-body-container {
            background-color: gray(7);
            overflow: auto;
            max-height: 70vh;
            @include breakpoint(small down) {
                overflow-x: scroll;
            }
            @include breakpoint(large down) {
                max-height: 100%;
                width: 100%;
            }
            .pdp-body {
                display: none;
                margin: rem-calc(32 48);
                @include breakpoint(medium down) {
                    margin: rem-calc(32 24);
                }
                &.active {
                    display: block;
                }

                &.sizeGuideWrapper {
                    $accentColorSizeGuide: rgb(246, 122, 26);
                    display: block;

                    .mainTitle {
                        color: gray(13);
                        text-transform: capitalize;
                        font-family: $body-font-family;
                        height: rem-calc(23);
                        font-size: rem-calc(16);
                        line-height: rem-calc(18);
                        font-weight: 500;
                        margin-bottom: rem-calc(16);
                        @include breakpoint(medium down) {
                            margin: rem-calc(0 24 16 24);
                        }
                    }

                    .sizeTableWrapper {
                        width: 100%;
                        margin-bottom: rem-calc(40);
                        overflow-x: auto;
                        @include breakpoint(medium down) {
                            margin-bottom: rem-calc(32);
                        }

                        .sizeTable {
                            width: 100%;
                            text-align: center;
                            font-size: rem-calc(12);
                            border-collapse: collapse;
                            border-top: rem-calc(1) solid gray(6);

                            @include breakpoint(medium down) {
                                width: rem-calc(560);
                            }

                            thead {
                                color: $accentColorSizeGuide;
                                font-weight: 700;
                            }

                            td {
                                padding: rem-calc(10) 0;
                                margin: 0;
                                @include breakpoint(medium down) {
                                    width: rem-calc(140);
                                }
                            }

                            .sizeTableRow {
                                border-bottom: rem-calc(1) solid gray(6);
                            }

                            .greyCell {
                                background-color: gray(7);
                            }
                        }
                    }

                    .sizeTableWrapper::-webkit-scrollbar {
                        height: rem-calc(3);
                        background-color: #ffdcc1;
                    }
                    .sizeTableWrapper::-webkit-scrollbar-thumb {
                        background-color: $accentColorSizeGuide;
                    }

                    .mainDescription {
                        width: 100%;
                        justify-content: flex-start;
                        display: flex;

                        @include breakpoint(medium down) {
                            width: calc(100% - 48px);
                            margin: rem-calc(0 24);
                        }

                        .descriptionLeft {
                            flex-grow: 0.2;
                            .measureLinkWrapper {
                                font-size: rem-calc(14);
                                line-height: rem-calc(18);
                                color: gray(12);
                                margin-bottom: rem-calc(32);
                                font-weight: 500;
                                @include breakpoint(medium down) {
                                    margin-bottom: rem-calc(24);
                                }
                            }
                            .mobileSizeGuideIllustration {
                                display: none;
                                text-align: center;
                                @include breakpoint(medium down) {
                                    display: block;
                                    text-align: center;
                                    margin-bottom: rem-calc(23);

                                    img {
                                        width: rem-calc(142);
                                    }
                                }
                            }
                        }

                        .descriptionRight {
                            flex-grow: 1;
                            @include breakpoint(medium down) {
                                display: none;
                            }
                        }

                        .descriptionSection {
                            .descriptionTitle {
                                display: flex;
                                height: rem-calc(20);
                                margin-bottom: rem-calc(8);
                                .titleNumber {
                                    display: block;
                                    width: rem-calc(20);
                                    height: rem-calc(20);
                                    border-radius: rem-calc(20);
                                    background-color: $accentColorSizeGuide;
                                    color: white;
                                    text-align: center;
                                    margin-right: rem-calc(8);
                                    font-size: rem-calc(12);
                                    line-height: rem-calc(18);
                                    padding: rem-calc(1 0);
                                }
                                .title {
                                    font-size: rem-calc(14);
                                    color: $accentColorSizeGuide;
                                    line-height: rem-calc(18);
                                    margin: rem-calc(1 0);
                                    font-weight: 500;
                                }
                            }
                            .descriptionInfo {
                                font-size: rem-calc(14);
                                line-height: rem-calc(18);
                                margin-bottom: rem-calc(24);
                                font-weight: 500;
                                color: gray(13);
                            }
                        }
                    }

                    &.swimwear {
                        .mainTitle {
                            margin-bottom: rem-calc(24);
                        }
                        .measureLinkWrapper {
                            margin-bottom: rem-calc(0);
                            line-height: rem-calc(18);
                            height: rem-calc(20);
                            @include breakpoint(medium down) {
                                margin-top: rem-calc(32);
                            }
                        }

                        .mainDescription {
                            justify-content: flex-start;
                            @include breakpoint(medium down) {
                                width: calc(100% - 40px);
                                margin: rem-calc(0 20);
                            }
                            .descriptionLeft {
                                flex-grow: 0.2;
                                @include breakpoint(medium down) {
                                    width: 100%;
                                }

                                .descriptionSection:nth-of-type(1) {
                                    margin-top: rem-calc(24);
                                }

                                .swimwearCalculator {
                                    margin-top: rem-calc(40);
                                    width: rem-calc(400);
                                    @include breakpoint(medium down) {
                                        margin-top: rem-calc(0);
                                        width: 100%;
                                    }

                                    .swimwearInput {
                                        display: block;
                                        width: 100%;
                                        height: rem-calc(40);
                                        text-align: left;
                                        border: rem-calc(1) solid gray(14);
                                        padding: rem-calc(12.5 20);
                                        font-size: rem-calc(12);
                                        line-height: rem-calc(15);
                                    }
                                    .swimwearInput:focus {
                                        color: black;
                                        border: rem-calc(1) solid black;
                                    }
                                    .swimwearInput:not(:placeholder-shown) {
                                        color: black;
                                    }
                                    .swimwearInput:placeholder-shown {
                                        color: gray(14);
                                    }
                                    .swimwearInput:nth-of-type(1) {
                                        margin-bottom: rem-calc(16);
                                    }
                                    .swimwearButton {
                                        display: block;
                                        width: 100%;
                                        height: rem-calc(36);
                                        margin-top: rem-calc(24);
                                        margin-bottom: rem-calc(16);
                                        border: rem-calc(1) solid black;
                                        padding: rem-calc(8.5 0);
                                        text-align: center;
                                        text-transform: capitalize;
                                        font-size: rem-calc(13);
                                        line-height: rem-calc(19);
                                    }
                                    .swimwearButton:hover {
                                        color: white;
                                        background-color: black;
                                        cursor: pointer;
                                    }
                                    .sizeCalculatorResult {
                                        width: 100%;
                                        height: rem-calc(40);
                                        padding: rem-calc(10 0);
                                        font-size: rem-calc(14);
                                        line-height: rem-calc(20);
                                        text-align: center;
                                        color: $light-orange;
                                        border: rem-calc(1) solid $light-orange;
                                    }
                                }

                                .mobileSizeGuideIllustration {
                                    text-align: center;
                                    @include breakpoint(medium down) {
                                        text-align: center;
                                        img {
                                            width: rem-calc(111);
                                        }
                                    }
                                }
                            }

                            .descriptionRight {
                                margin-left: rem-calc(114);
                                flex-grow: 1;
                                img {
                                    width: rem-calc(145);
                                }
                            }
                        }

                        .swimwearShowTableLink {
                            margin-top: rem-calc(32);
                            @include breakpoint(medium down) {
                                display: none;
                            }

                            .showSizeGuideTableLink {
                                text-decoration: underline;
                            }
                        }

                        .sizeTableWrapper {
                            margin-top: rem-calc(24);
                        }
                    }
                }
            }
        }
    }
}

.quantity-button.disabled {
    pointer-events: none;
    opacity: 0.2;
}

.icon_category_image {
    height: 1rem;
    width: 1rem;
}

.product-name {
    h3 {
        min-height: rem-calc(56);
        height: auto;
        padding: 0;
        margin: 0;

        @include breakpoint(small down) {
            min-height: auto;
            font-size: rem-calc(13);
            line-height: rem-calc(17);
        }
    }
    .product-name-subsection {
        display: flex;
        align-items: inherit;
        padding-top: rem-calc(8);
        @include breakpoint(small down) {
            padding-bottom: 0;
            align-items: baseline;
        }
        .wishlist-icon {
            @include breakpoint(small down) {
                padding-left: 0;
            }
        }
        .pdp-title {
            font-size: rem-calc(20);
            text-transform: none;
            line-height: rem-calc(26);
            font-weight: 400;
            font-family: 'Spezia', Helvetica, Arial, sans-serif;
            @include breakpoint(small down) {
                padding-right: rem-calc(10);
            }
            @include breakpoint(medium down) {
                font-size: rem-calc(16);
                text-transform: none;
                line-height: rem-calc(22);
                font-weight: 400;
                padding-left: rem-calc(16.5);
            }
        }
        &.world-site {
            margin-bottom: rem-calc(24);
        }
    }

    .price {
        font-weight: 500;

        .strike-through ~ .sales {
            .value {
                color: $primary;
                margin-left: rem-calc(10);
                font-size: rem-calc(12);
            }
        }
    }

    .marketing-messages.pdp {
        color: $primary;
        font-size: rem-calc(18);

        .promo-message {
            font-size: rem-calc(18);
        }
    }
}

.sales {
    .discount-font {
        font-size: rem-calc(12);
    }
}

.slick-arrow.slick-disabled {
    pointer-events: none;
}

.bonus-product-item {
    .slick-disabled {
        pointer-events: all;
    }
}

.page[data-action='Product-Show'] {
    @include breakpoint(medium up) {
        .crosssell-view {
            float: left;
            margin-bottom: rem-calc(25);
        }
    }
}
.new-message-black-box {
    color: black;
    height: rem-calc(16);
    padding: rem-calc(0 3);
    font-size: rem-calc(7);
}

@include breakpoint(small down) {
    // Fix for scroll
    .page[data-action='Product-Show'] {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .page[data-action='Product-Show'] .main-header {
        position: relative;
    }

    .fixed-at-top-small-only {
        top: 0;
        position: fixed !important;
    }

    .neg-margin {
        margin-right: -(map-get($grid-margin-gutters, small) / 2);
        margin-left: -(map-get($grid-margin-gutters, small) / 2);
    }

    .add-to-cart {
        padding: rem-calc(10);

        &.persButtonPdpAdd {
            @include breakpoint(small down) {
                position: relative;
                float: right;
                width: 50%;
                margin-bottom: 0;
            }
        }
    }

    .overlay-minicart-mobile {
        position: fixed;
        display: none;
        top: rem-calc(71);
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
    }

    #descriptionSidebar {
        padding: rem-calc(16 0);

        & .prices {
            margin-top: auto;
            margin-bottom: 0;
            font-size: rem-calc(14); // for pdp
            @include breakpoint(small down) {
                padding: rem-calc(0 16.5);
            }
        }
    }

    .scalapay-widget {
        margin: 0;
        padding-left: rem-calc(16.5);
    }

    #menuSelectBarMobile {
        position: fixed;
        bottom: 0;
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
            padding-bottom: env(safe-area-inset-bottom);
            z-index: 999;
        }
        left: 0;
        z-index: 850;
        margin-left: 0;
        // important here is used to make everything work on
        // both IE and Safari for iOS (mobile)
        width: 100%;
        top: initial;
        background-color: $white;
    }

    .product-name {
        font-size: rem-calc(11);
        letter-spacing: normal;
        padding-top: rem-calc(1);
        padding-bottom: rem-calc(3);

        @include breakpoint(medium down) {
            line-height: rem-calc(22);
            font-size: rem-calc(16);
        }
        &.world-site {
            @include breakpoint(medium down) {
                padding: rem-calc(0 16.5);
            }
        }
        &.bonus {
            padding: 0;
            @include breakpoint(medium down) {
                font-size: rem-calc(16);
                margin-bottom: rem-calc(8);
                padding: rem-calc(0 16);
            }
        }
    }

    .inactive-logic {
        .button {
            opacity: 0.4;
            border: rem-calc(1) solid $black;
        }
    }

    .icon-container {
        position: fixed;
        width: 100%;
        padding: rem-calc(30 7);
        left: 0;
        z-index: 1;

        &.at-top {
            top: rem-calc(0);
            @include breakpoint(small down) {
                .aligned-right {
                    margin-right: rem-calc(7);
                }
            }
        }

        & .aligned-right {
            float: right;

            & .find-in-store-icon {
                display: flex;
                margin-right: rem-calc(10);
                justify-content: center;
                align-items: center;

                & svg.big {
                    width: rem-calc(20);
                }
            }
        }

        & .slider-pos {
            position: relative;
            margin: 0;
            width: rem-calc(20);
        }
    }
}

// slider pos and miniature
.icon-container {
    box-sizing: border-box;

    .aligned-right {
        @include breakpoint(small down) {
            width: rem-calc(35);
            margin-right: rem-calc(8);
        }
    }

    & .slider-pos {
        @include breakpoint(small down) {
            position: fixed;
            margin-left: rem-calc(20);
            margin-top: rem-calc(20);

            right: 2%;
            top: 40%;

            height: 50%;
            width: rem-calc(3);
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
            right: rem-calc(30);
            z-index: 100;

            .slide:not(.video-dots) {
                opacity: 0.7;
                content: '';
                width: rem-calc(5);
                height: rem-calc(5);
                border-radius: 50%;
                border: 0;
                background: $medium-gray;
                margin-left: rem-calc(1);
                margin-bottom: rem-calc(5);

                &.active {
                    opacity: 1;
                    background: $primary;
                }
            }
            .video-dots {
                position: relative;
                height: rem-calc(8);
                width: rem-calc(9);
                opacity: 0.7;
                margin-left: rem-calc(1);
                margin-bottom: rem-calc(5);
                &.active {
                    opacity: 1;
                }
                .videoMiniatureIcon {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

// end slider pos
.miniatureVerticalImg {
    @include breakpoint(medium up) {
        position: sticky;
        top: 50%;
        overflow: auto;
        height: 57vh;
        width: 4.83vw;
    }
    width: rem-calc(100);
    .slider-quick {
        width: 3.66vw;
        margin: rem-calc(8 8 0 8);

        .slide {
            margin-bottom: rem-calc(4);
            opacity: 1;

            &.slick-current {
                opacity: 0.4;
            }
        }
    }

    .slider-pos {
        width: rem-calc(60);
        margin-left: rem-calc(20);
        margin-right: rem-calc(20);

        .slide:not(.video-dots) {
            width: rem-calc(5);
            height: rem-calc(5);
            border-radius: 50%;
            background: $medium-gray;
            opacity: 1;
            margin-bottom: rem-calc(5);
            &.active {
                opacity: 0.7;
                background: $primary;
            }
        }
        .video-dots {
            position: relative;
            height: rem-calc(8);
            width: rem-calc(9);
            margin-bottom: rem-calc(5);
            opacity: 0.7;
            &.active {
                opacity: 1;
            }
            .videoMiniatureIcon {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &::-webkit-scrollbar {
        @include breakpoint(medium up) {
            display: none;
        }
    }
}

.bonus-product-item {
    .miniatureVerticalImg {
        @include breakpoint(medium up) {
            height: rem-calc(470);
        }

        .slick-list {
            height: unset !important;
        }

        .slick-track {
            position: static;
        }
    }
}

.middle-side-modal {
    @include breakpoint(medium up) {
        width: 22.7vw;
    }
}

.bonus-product-item {
    .middle-side-modal {
        @include breakpoint(medium up) {
            width: rem-calc(310);
        }

        .product-image > img {
            @include breakpoint(large up) {
                height: rem-calc(470);
            }
        }
    }
}

.right-side-modal {
    @include breakpoint(medium up) {
        width: 36.23vw;
        position: relative;
    }
    @include breakpoint(medium down) {
        position: sticky;
        bottom: 0;
    }
}

.divider-vertical-pdp {
    @include breakpoint(large up) {
        margin-right: 50%;
        border-right: 1px solid $border-gray;
        height: 100%;
    }
}

.pdp-divider {
    width: 100%;
    margin-bottom: rem-calc(100);
    max-width: 100%;
}

/*.linkModalGetProductInfo, */
.smart-gift-button {
    cursor: pointer;
    width: 100%;
    height: rem-calc(40);
    position: relative;
    margin-bottom: rem-calc(16);
    margin-top: rem-calc(2px);
    border: rem-calc(1) solid black;
    font-weight: 500;
    svg {
        height: rem-calc(17);
    }
}
.find-in-store-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(40);
    .find-in-store-btn {
        cursor: pointer;
        span {
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: rem-calc(20);
            border-bottom: rem-calc(1) solid black;
            font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
        }
        &:hover {
            color: $dark-gray;
        }
    }
}
.smart-gift-button {
    justify-content: center;
    align-items: center;
    /* p{
        margin: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(19.5);
    } */
    .smart-gift-cta-title {
        font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
    }
    &:hover {
        color: $white;
        background: rgba(0, 0, 0, 0.4);
        border-color: transparent;
        .smart-gift-cta-title {
            color: white;
        }
        .smart-gift-image {
            filter: invert(1);
        }
    }
}

.smart-gift-image {
    margin-right: rem-calc(13);
    width: rem-calc(14);
    height: rem-calc(16);
    margin-left: rem-calc(40);
    @include breakpoint(medium down) {
        margin-left: 0;
    }
}
.smart-gift-cta-title {
    color: black;
    font-size: rem-calc(14);
    line-height: rem-calc(19);
    font-weight: 600;
    margin: 0;
    @include breakpoint(medium down) {
        text-align: center;
        width: auto !important;
    }
}
.smart-gift-button.rules {
    border-left: 0;
    color: black;
    &:hover {
        .question-tag-sg {
            filter: invert(1);
        }
    }
}

.smartGiftModal {
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(80px);
    .tingle-modal-box {
        overflow: auto;
        @include breakpoint(medium up) {
            width: rem-calc(828);
            max-width: unset;
        }
    }
    .tingle-modal-box__content {
        padding: rem-calc(46 64);
        backdrop-filter: blur(80px);
        @include breakpoint(medium down) {
            padding: rem-calc(32 0);
        }
    }
    .tingle-modal__close.smart-gift-close {
        display: block;
        svg {
            position: absolute;
            right: rem-calc(72);
            width: rem-calc(16);
            height: rem-calc(16);
            top: rem-calc(56);
            cursor: pointer;
            @include breakpoint(medium down) {
                right: rem-calc(21);
                top: rem-calc(36);
            }
        }
    }
    .modalSmartGift {
        .smart-gift-help-content {
            @include breakpoint(medium down) {
                padding: rem-calc(0 32);
            }
        }
        .smart-gift-help-title {
            text-align: center;
            font-size: rem-calc(24);
            font-weight: 500;
            line-height: rem-calc(36);
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: rem-calc(12);
            margin-bottom: rem-calc(20);
            @include breakpoint(medium down) {
                font-size: rem-calc(18);
                line-height: rem-calc(27);
                text-align: left;
                padding-left: rem-calc(32);
            }
        }
        .item-help {
            font-weight: 500;
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            margin-bottom: rem-calc(12);
        }
        .item-help-description {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            @include breakpoint(medium down) {
                font-size: rem-calc(15);
                line-height: rem-calc(22.5);
            }
        }
    }
}

.return-info {
    margin-bottom: rem-calc(20);
}

#notifications {
    position: fixed;
    z-index: 1020;
    top: 0;

    & .header-message.dismissable {
        height: unset !important;
    }
}

.recommendations {
    &.recentlyviewed {
        & .outer-container {
            overflow: hidden;

            @include breakpoint(small down) {
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;

                & .wrapper {
                    max-width: rem-calc(1030);
                }
            }
        }
    }

    & .recently-viewed {
        width: 8%;
        margin-right: 1.8%;

        display: inline-block;

        @include breakpoint(small down) {
            width: rem-calc(85);
            margin-right: rem-calc(15);

            & .plp-wishlist {
                display: none;
            }
        }

        & .price-container {
            text-align: center;
        }
    }
}

// ModalImageCarousel
.wrapperModalImageCarousel {
    &.tingle-modal {
        z-index: 2001;
    }

    .tingle-modal__close {
        display: inline-block;
        color: $primary;
    }

    .tingle-modal__close-wrapper {
        position: absolute;
        display: block;
        text-align: right;
        z-index: 999;
        .tingle-modal__closeIcon{
            background-size: 100%;
            color: transparent;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            width: 100%;
            height: 100%;
            background-image: url("data:image/svg+xml,%3Csvg%20viewBox='0%200%2024%2024'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M4.14645%2019.1464C3.95118%2019.3417%203.95118%2019.6583%204.14645%2019.8536C4.34171%2020.0488%204.65829%2020.0488%204.85355%2019.8536L12%2012.7071L19.1464%2019.8536C19.3417%2020.0488%2019.6583%2020.0488%2019.8536%2019.8536C20.0488%2019.6583%2020.0488%2019.3417%2019.8536%2019.1464L12.7071%2012L19.8536%204.85355C20.0488%204.65829%2020.0488%204.34171%2019.8536%204.14645C19.6583%203.95118%2019.3417%203.95118%2019.1464%204.14645L12%2011.2929L4.85355%204.14645C4.65829%203.95118%204.34171%203.95118%204.14645%204.14645C3.95118%204.34171%203.95118%204.65829%204.14645%204.85355L11.2929%2012L4.14645%2019.1464Z'%20fill='black'/%3E%3C/svg%3E");
        }
    }

    .tingle-modal-box {
        border-radius: rem-calc(0);
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        max-width: 100vh;
        margin: 0;
        padding: 0;

        .tingle-modal-box__content {
            padding: 0;
        }
    }
}

.product-detail {
    max-width: 100%;
    @include breakpoint(large up) {
        margin-top: rem-calc(15);
    }

    .breadcrumb {
        @media screen and (max-width: 768px) {
            display: flex;
            margin: rem-calc(12) 0 rem-calc(12) 0;
            padding-left: calc(9% - 18px);
            padding-right: calc(9% - 18px);
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            display: none;
        }
        @include breakpoint(medium up) {
            display: flex;
            margin-bottom: rem-calc(16);
            margin-left: 0;
        }
        @include breakpoint(medium up) {
            padding: rem-calc(0 40);
        }
    }

    h4 {
        font-weight: 500;
        padding-right: rem-calc(20);
    }
}
.product-availability {
    .availability-msg {
        font-size: rem-calc(10);
        line-height: rem-calc(14);
        margin-top: rem-calc(8);
    }
}

.crosssell-view .promo-message {
    height: 1rem;
    margin-bottom: rem-calc(5);
}
.promo-info-icon {
    vertical-align: middle;
}
#promoMessage.marketing-budge {
    padding-left: 0;
    line-height: rem-calc(18);
    word-wrap: break-word;
    color: $success-notification;
    cursor: pointer;
    font-size: rem-calc(12);
    margin-top: rem-calc(7);
    margin-bottom: rem-calc(5);
    font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
}
.marketing-budge {
    color: $sales;
    font-size: rem-calc(20);
    font-weight: 600;
    line-height: rem-calc(26);
    letter-spacing: rem-calc(0.2);
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    @include breakpoint(small down) {
        text-overflow: ellipsis;
        line-height: rem-calc(14);
        padding-left: rem-calc(16);
        font-size: rem-calc(14);
    }
    &:not(.hidden) {
        display: inline-block;
    }
}

.wishlist-button {
    display: inline-flex;
    margin-left: rem-calc(80);
    @include breakpoint(small down) {
        margin-left: 0;
    }

    .cc-wishlistIconSvg {
        width: rem-calc(24);
        height: rem-calc(24);
    }
}

a.add-to-wish-list.added {
    color: $primary;
    g {
        fill: $primary;
    }
}

.text-primary {
    color: $sales;
    padding-left: rem-calc(150);
}

.page[data-action='Product-Show'] {
    .price .strike-through ~ .sales {
        font-size: rem-calc(14);
        @include breakpoint(medium down) {
            font-size: rem-calc(16);
        }
    }
    .price {
        .strike-through.list {
            font-size: rem-calc(20);
            @include breakpoint(medium down) {
                font-size: rem-calc(16);
            }
        }
    }
}

.price .strike-through ~ .sales {
    &.loyalty1,
    &.loyalty2,
    &.loyalty3,
    &.loyalty4,
    &.loyaltysale1,
    &.loyaltysale2,
    &.loyaltysale3,
    &.loyaltysale4 {
        color: $loyalty;
    }
}

.price .strike-through {
    color: $light-gray;
}

.sales.percentage {
    &.loyalty1,
    &.loyalty2,
    &.loyalty3,
    &.loyalty4,
    &.loyaltysale1,
    &.loyaltysale2,
    &.loyaltysale3,
    &.loyaltysale4 {
        color: $loyalty !important;
    }
    &.sale {
        color: $sales !important;
    }
}

.primary-images {
    overflow: hidden;

    //ratio img
    .carousel-item {
        margin-bottom: rem-calc(11);
        width: auto;
        height: auto;

        @include breakpoint(xlarge down) {
            max-width: rem-calc(484);
        }

        @include breakpoint(medium down) {
            max-width: inherit;
            margin-bottom: rem-calc(0);
            max-height: inherit;
        }
        &.world-site {
            @include breakpoint(medium up) {
                max-width: unset;
            }
        }
    }

    @include breakpoint(small down) {
        .slick-dots {
            position: absolute;
            top: 25%;
            bottom: 25%;
            height: 50%;
            width: rem-calc(3);
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
            right: rem-calc(24);

            li {
                position: relative;
                display: block;
                width: rem-calc(3);
                height: rem-calc(20);
                margin: rem-calc(5);
                padding: 0;
                cursor: pointer;

                button {
                    &:before {
                        opacity: 1;
                        content: '';
                        width: rem-calc(1);
                        height: rem-calc(20);
                        border-radius: 0;
                        border: 0;
                        background: #000000;
                        margin-left: rem-calc(1);
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            opacity: 1;
                            width: rem-calc(3);
                            height: rem-calc(20);
                            border-radius: 0;
                            border: 0;
                            background: #000;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.carousel-select-color {
    @include breakpoint(medium down) {
        &::-webkit-scrollbar {
            display: none;
        }
        &.scroll {
            .color-variation {
                width: rem-calc(29.5);
            }
        }
    }
}

.carousel-select-color,
.carousel-select-size {
    width: auto;
    margin-top: 0;

    @include breakpoint(medium down) {
        width: auto;
    }

    @include breakpoint(small down) {
        margin-top: 0;
        .slick-slide {
            &:last-child {
                a {
                    &.color-variation {
                        margin-right: rem-calc(20);
                    }
                }
            }
        }
    }

    div {
        margin: 0;
        padding: 0;
        @include breakpoint(medium down) {
            &:first-child {
                a {
                    &.color-variation {
                        padding-left: rem-calc(2);
                    }
                }
            }
        }

        @include breakpoint(large up) {
            display: inline-block;
            margin-right: rem-calc(10);
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 0;
        line-height: 1;
        opacity: 0.75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .slick-next {
        right: 0;
        background-color: rgba(255, 255, 255, 0.7);
        height: rem-calc(22);
        width: calc(100% / 22);

        &:before {
            z-index: 999;
        }

        &.slick-disabled {
            background-color: transparent;

            &:before {
                opacity: 0;
            }
        }
    }

    .slick-prev {
        left: 0;
        background-color: rgba(255, 255, 255, 0.7);
        height: rem-calc(22);
        width: calc(100% / 22);
        z-index: 9;

        &.slick-disabled {
            background-color: transparent;

            &:before {
                opacity: 0;
            }
        }
    }
}

.complete-tile-recommendation {
    @include breakpoint(medium down) {
        .productDescription {
            margin-bottom: rem-calc(16);
            padding: rem-calc(16 20 10 8);
        }
    }
}

.carousel-select-size {
    padding-top: 0;
    height: 100%;

    .slick-next,
    .slick-prev {
        height: 100%;
    }
}

.margin-right-small {
    @include breakpoint(medium down) {
        margin-right: rem-calc(18);
    }
}

.product-quickview .carousel-select-color {
    .slick-next,
    .slick-prev {
        width: calc(100% / 6);
    }
}

.box-carousel-root {
    width: 100%;
    max-width: 100%;
    margin-bottom: rem-calc(40);
    @include breakpoint(medium down) {
        margin-bottom: rem-calc(24);
    }
    &.loaded {
        display: block;
    }

    .box-carousel-items-wrapper {
        display: block;
        position: relative;
        #crossSellContainer {
            .slick-list {
                margin-left: 0;
                padding-bottom: rem-calc(40);
            }
            .crosssell-view {
                margin-bottom: 0;
                .product {
                    .productDescription {
                        margin-bottom: rem-calc(46);
                        padding-top: rem-calc(16);
                        padding-left: rem-calc(8);
                        padding-bottom: rem-calc(10);
                        @include breakpoint(medium down) {
                            padding: rem-calc(16 16 0 16);
                        }
                        .productDescription-name {
                            margin-bottom: rem-calc(8);
                            .pdp-link {
                                margin-top: 0;
                            }
                        }
                    }
                    .productDescription-priceRow {
                        align-items: flex-start;
                        .price-container {
                            margin-top: 0;
                        }
                    }
                }
                .quick-buy-sizes-row {
                    padding: 0;
                    .quick-buy-sizes-item {
                        margin: rem-calc(0 12 8);
                    }
                }
            }
        }
    }
    .complete-your-promo-description {
        @include breakpoint(medium up) {
            position: relative;
            width: 18.96046852122987vw;
            background: $background-promo;
            padding: rem-calc(24 24 24);
            height: 26.5519766vw;
            margin-right: rem-calc(8);
        }
        .cross-sell-promo-label {
            font-size: rem-calc(12);
            font-weight: 600;
            line-height: rem-calc(20);
        }
    }
}

/* MODALS in PDP */
.linkModalFindYourSize {
    border-bottom: rem-calc(1) solid black;
    font-size: rem-calc(14);
    font-weight: 600;
    font-family: $secondary-font;
    color: $outrageous-orange;
    @include breakpoint(large up) {
        padding-bottom: rem-calc(2);
        border-color: $outrageous-orange;
        border-bottom-width: rem-calc(1);
    }
    @include breakpoint(medium down) {
        line-height: rem-calc(20);
    }
    &:hover {
        text-decoration: none;
        cursor: pointer;
        color: $dark-gray;
    }
}
.linkModalFindYourSize.modal-mobile {
    border-bottom: rem-calc(1) solid $outrageous-orange;
    padding-bottom: rem-calc(2);
    &.bonus {
        color: black;
    }
}
.size-guide-section {
    text-align: center;
    width: 100%;
    padding-bottom: rem-calc(3);
    @include breakpoint(small down) {
        padding-bottom: rem-calc(17);
    }
}

.bonus-product-item {
    .size-guide-section {
        @include breakpoint(large down) {
            padding: 0;
        }
    }
}

.carousel-item {
    position: relative;
}

@import 'plp/tilemessagging';

.product-freegift {
    .attributes {
        .product-availability {
            margin-bottom: 0 !important;
        }
    }
}

.bonus-product-modal {
    .carousel-select-color {
        .color-variation {
            display: unset;
            .selectable-swatches__circle {
                height: rem-calc(24);
                width: rem-calc(24);
                margin-right: rem-calc(16);
                &:before {
                    width: rem-calc(29);
                    height: rem-calc(29);
                }
            }
        }
    }
    @include breakpoint(medium down) {
        #menuSelectBarMobile {
            position: relative !important;
            padding: rem-calc(0);
        }
    }
}

.banner-free-gift {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    background-color: $neutral;
    font-weight: 600;
    padding-left: rem-calc(40);
    font-size: rem-calc(12);
    align-items: center;
    display: flex;
    vertical-align: middle;
    align-content: space-between;

    @include breakpoint(small down) {
        width: 100%;
        z-index: 999;
        position: fixed;
        margin-left: 0;
        top: 0;
        padding-left: rem-calc(20);
    }
    .gift-icon {
        margin-right: rem-calc(13);
        width: rem-calc(14);
        height: rem-calc(16);
    }

    .gift-text {
        padding-right: rem-calc(15);
    }
}

.grid-container.full-only-mobile {
    @include breakpoint(small down) {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }
    @include breakpoint(medium down) {
        .grid-margin-x {
            margin-left: 0;
            margin-right: 0;
        }
        .js-select-color-for-mobile {
            border-top: 1px solid $border-gray;
            &.grid-margin-x > .cell {
                margin: 0;
                width: 100%;
            }
            @include breakpoint(small down) {
                border-top: 0;
            }
        }
        .carousel-color-scrolling {
            .js-mobile-scroll-color {
                display: inline;
                a {
                    width: calc(100% / 10);
                }
            }
            &.carousel-select-color {
                white-space: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;
                @include breakpoint(medium down) {
                    padding-left: rem-calc(4);
                    padding-top: rem-calc(4);
                    display: block;
                }
            }
        }
    }
    @include breakpoint(medium up) {
        padding-left: rem-calc(0);
        padding-right: rem-calc(0);
    }
}

.product-card-info-container {
    @include breakpoint(medium up) {
        padding: rem-calc(0 40 20 40);
    }

    .price-info-pdp-container {
        margin: 0;
        max-width: none;

        p.price-info {
            margin-bottom: 0;
        }
    }

    .discount-percentage {
        display: none !important;
    }
}

.component-four-product-pdp {
    .title-component-four-product {
        margin-bottom: rem-calc(20);
    }
    .background-component-hp-four-slider {
        background: none;
        padding: 0;
        @include breakpoint(medium down) {
            padding: rem-calc(0 30);
        }
        .title-home-component {
            display: none;
        }
    }
}

.see-product-border-top {
    margin-top: rem-calc(15);
    display: flex;
    justify-content: flex-end;
    a {
        font-size: rem-calc(11);
        line-height: rem-calc(16);
    }
}

.boxGrayMoreInformationPdpCalzedonia {
    background-color: #f7f7f7;
    padding: 10px;
    p {
        max-width: rem-calc(400);
        margin: 0 auto;
    }
}
#inputSeno {
    margin-bottom: rem-calc(16);
}
#inputSottoSeno {
    margin-bottom: 0;
}
.result-calzedonia-tool-fys {
    margin-top: 0;
    border: transparent;
    text-align: center;
    .size-calculator-result {
        margin-bottom: 0;
        line-height: rem-calc(13);
    }
    .result-wrapper {
        color: $light-orange;
        border: solid 1px #f57a1a;
        padding: rem-calc(14 0);
        text-align: center;
        #box-result-size-from-pdp-measure-valid,
        #measure-not-valid-from-pdp {
            color: $light-orange;
            line-height: rem-calc(13);
            margin: 0;
        }
    }
}
#measure-not-valid-from-pdp {
    display: none;
}

.marT41 {
    margin-top: rem-calc(41);
}

.table-find-your-size,
.table-find-your-size-table-calzedonia {
    border-spacing: 0;

    width: 100%;
    text-align: center;

    tr {
        th,
        td {
            width: calc(100% / 7);
            text-align: center;
            border-bottom: rem-calc(1) solid #eeeeee;

            &.size-legend {
                background-color: $light-over-gray;
            }

            &.size-active {
                background-color: $black;
                color: $white;
            }

            &.size-same {
                background-color: $medium-gray;
            }
        }

        th {
            line-height: rem-calc(22);
        }

        td {
            line-height: rem-calc(38);
        }
    }
}

.table-find-your-size-table-calzedonia {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(30);
    text-align: center;

    tr {
        th,
        td {
            width: auto;
        }

        th {
            font-size: rem-calc(11);

            @include breakpoint(small down) {
                font-size: rem-calc(10);
            }
        }
    }
}
.bg-image-a {
    background: url(../../images/a.png) 7px 7px;
    padding: 8px 39px;
    height: rem-calc(41);
    background-size: 25px 25px;
    background-repeat: no-repeat;
}
.bg-image-b {
    background: url(../../images/b.png) 7px 7px;
    padding: 8px 39px;
    height: rem-calc(41);
    background-size: 25px 25px;
    background-repeat: no-repeat;
}
.bg-image-c {
    background: url(../../images/c.png) 7px 7px;
    padding: 8px 39px;
    height: rem-calc(41);
    background-size: 25px 25px;
    background-repeat: no-repeat;
}

.inputInvalid {
    border: 1px solid #fd3d3d;
    background-color: #ffecec;
}

.inputInvalid::-webkit-input-placeholder {
    color: #dd2b2e;
}
.rightModal {
    right: -500px;
}

.transition {
    transition: 0.8s;
    overflow: auto;
}

.rightModalAttribute {
    position: absolute;
    right: 0;
    width: 100%;
    overflow-y: auto;
}

.modal__closeIconCalzedonia {
    width: 13px !important;
    height: 29px !important;
}
.tingle-modal-box.get-product-info-modal {
    @include breakpoint(small down) {
        max-width: 100vw !important;
    }
    button {
        cursor: pointer;
    }
}
.modalGetProductInfo {
    .modalTitle {
        border-top: 1px solid $neutral;
    }

    .product_authenticity {
        margin-bottom: rem-calc(22);
    }

    .product-authenticity-content {
        .product_madeIn {
            margin: rem-calc(22 0);
            .js-modal-made-in {
                cursor: pointer;
            }
        }
        .product_logistic {
            .js-modal-made-in {
                cursor: pointer;
            }
        }
    }

    .productInfo-container {
        border-bottom: 1px solid $neutral;
        padding: rem-calc(25 0);
        &-description {
            padding-bottom: rem-calc(25);
            @include breakpoint(medium down) {
                padding-top: rem-calc(30);
            }
        }
    }

    .productInfo-title {
        margin-bottom: 0;
        font-size: rem-calc(15);
        font-weight: 600;
        line-height: rem-calc(22);
        font-family: 'Spezia', Helvetica, Arial, sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .productInfo-arrow {
            float: right;
            cursor: pointer;
            &.open-container {
                .minus {
                    display: block;
                }
                .plus {
                    display: none;
                }
            }
            .plus {
                display: block;
            }
            .minus {
                display: none;
            }
        }
    }

    .productInfo-content {
        margin-top: rem-calc(22);
        .accordion-content {
            border: none;
            padding: unset;
            .tab-content h6,
            .js-composition-info {
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(14);
            }
            li {
                &:not(:last-of-type) {
                    border: none;
                }
            }
        }
    }
}

.made-in-modal-background {
    backdrop-filter: blur(80px);
}
.made-in-modal {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(80px);
    @include breakpoint(medium up) {
        width: 38.0673499vw;
    }
    @media screen and (min-width: 375px) and (max-width: 700px) {
        height: 100vh;
        width: 100vw;
        margin: 0;
        max-height: unset;
        backdrop-filter: unset;
    }
    .tingle-modal-box__content {
        padding: 0;
        .close {
            right: rem-calc(40);
            top: rem-calc(40);
            z-index: 1;
            @include breakpoint(small down) {
                top: rem-calc(20);
                right: rem-calc(20);
            }
            svg {
                width: rem-calc(15);
                height: rem-calc(15);
                @include breakpoint(medium up) {
                    filter: invert(1);
                }
            }
        }
        .wrapper-made-in-only-mobile {
            @media screen and (max-width: 700px) {
                background: white;
                min-height: rem-calc(73);
            }
            @include breakpoint(medium up) {
                display: none;
            }
        }
        .fabric-img-container {
            position: relative;
            img {
                width: 100%;
                min-height: rem-calc(120);
            }
            .fabric-info {
                width: 100%;
                padding: rem-calc(40 40 24);
                .fabric-place,
                .fabric-operation-category {
                    font-size: rem-calc(14);
                    margin-bottom: rem-calc(5);
                    margin-right: rem-calc(8);
                    &-value {
                        font-size: rem-calc(14);
                        font-weight: 600;
                    }
                }
                @include breakpoint(small down) {
                    padding: rem-calc(40 16 24);
                }
            }
        }
        .fabric-description-cell,
        .production-chain-cell {
            margin-bottom: rem-calc(40);
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
            .js-analytics-modal-cta {
                font-size: rem-calc(14);
                font-weight: 600;
            }
            @include breakpoint(small down) {
                margin-bottom: rem-calc(43);
                padding: rem-calc(0 16);
            }
            .fabric-title,
            .production-chain-title {
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                font-weight: 600;
                margin-bottom: rem-calc(16);
                @include breakpoint(small down) {
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                }
            }
            .fabric-description {
                margin-bottom: rem-calc(30);
                font-size: rem-calc(14);
            }
        }
        .production-chain-cell {
            margin: rem-calc(40 0 40);
            @include breakpoint(small down) {
                padding: rem-calc(0 16);
                margin: rem-calc(40 0 55);
            }
            .production-chain-title {
                margin-bottom: rem-calc(32);
                text-align: left;
            }
            .js-analytics-modal-cta {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
        }
        .extended-for-mobile {
            @include breakpoint(small down) {
                width: 100%;
            }
        }
    }
}

.carousel-einstein-recently-viewed {
    margin-left: rem-calc(-5);
    @include breakpoint(large up) {
        margin-left: rem-calc(-15);
    }
    .product {
        margin: rem-calc(0 5);
        @include breakpoint(large up) {
            margin: rem-calc(0 15);
        }
    }
    .promo-message,
    .loyalty-msg,
    .js-discount-msg,
    .plp-tag {
        display: none;
    }
    .font-size-22-desk {
        font-size: inherit;
    }
    .price {
        .strike-through ~ .sales {
            font-size: rem-calc(13) !important;
        }
        .strike-through {
            font-size: rem-calc(12);
        }
    }
    .pdp-link,
    .add-to-wish-list,
    .product-tile:hover .hover-info-bag-wishlist {
        display: none;
    }
    .tile-body {
        .promo-message {
            margin: rem-calc(0 15);
        }
    }

    > .slick-next,
    > .slick-prev {
        top: 38%;
        &::before {
            background-size: rem-calc(25);
            width: rem-calc(30);
        }
    }
    > .slick-next {
        @include breakpoint(medium down) {
            top: rem-calc(-29);
            right: rem-calc(-11);
            &:before {
                background-image: url('../images/arrow-right-mobile.svg');
                background-size: rem-calc(6);
            }
        }
    }
    > .slick-prev {
        @include breakpoint(medium down) {
            top: rem-calc(-29);
            right: rem-calc(27);
            left: inherit;
            &:before {
                background-image: url('../images/arrow-left-mobile.svg');
                background-size: rem-calc(6);
            }
        }
    }
    .productDescription {
        padding-top: rem-calc(0);

        .productDescription-priceRow {
            margin-top: rem-calc(16);
        }
    }
}

.setsize-selected {
    border-bottom: rem-calc(3) solid $black !important;
}

.completeYourLookContainer {
    .crosssell-view {
        outline: none;
        .promo-message,
        .loyalty-msg,
        .js-discount-msg,
        .plp-tag {
            display: none;
        }
        .font-size-22-desk {
            font-size: inherit;
        }
        .price {
            .strike-through ~ .sales {
                font-size: inherit !important;
            }
        }
        .price-container {
            margin-top: rem-calc(30);
            font-size: rem-calc(12) !important;
            line-height: rem-calc(17);
            margin: rem-calc(8 0);
            margin-bottom: 0;
            .strike-through {
                display: inline-block;
                .value {
                    font-size: rem-calc(10);
                    line-height: rem-calc(12);
                }
            }
            @include breakpoint(medium down) {
                margin-top: rem-calc(10);
                margin-bottom: rem-calc(10);
            }
        }
    }
    .productDescription {
        margin-bottom: rem-calc(15);
    }
}

.product-detail-quick-add {
    font-size: rem-calc(10);
    @include breakpoint(small down) {
        padding-bottom: rem-calc(24);
    }
    .desktop-paddiing-left {
        @include breakpoint(large up) {
            padding-left: rem-calc(5);
        }
    }
    .modal-quick-add-size-quantity {
        background-color: $white;
        z-index: 1;
        .product-content-size {
            .product-content-size-row .swatch-boolean {
                justify-content: left;
                padding-left: rem-calc(10);
                margin-bottom: 0;
                width: 100%;
                border: none;
                .radio-label {
                    @include breakpoint(medium up) {
                        font-size: 10px;
                    }
                    @include breakpoint(small down) {
                        font-size: 9px;
                    }
                }
            }
        }
    }
    .add-to-cart-quick-add {
        width: 100%;
        background: $primary;
        color: $white;
        text-align: center;
        padding: rem-calc(10 5 10 5);
        &.item-added-quick {
            .add-to-cart-not-confirmed {
                display: none;
            }
        }
        &:not(.item-added-quick) {
            .add-to-cart-confirmed {
                display: none;
            }
        }
        &[data-disabled='true'] {
            opacity: $button-opacity-disabled;
            cursor: not-allowed;
        }
    }
    .size-quick-add-opener-container {
        border: rem-calc(1) solid $border-gray;
        padding: rem-calc(10 0 0);
        position: relative;
        @include breakpoint(small down) {
            margin-bottom: rem-calc(8);
            min-height: rem-calc(32);
        }
        .panel-opener-size-quick-add {
            text-transform: uppercase;
            padding-left: rem-calc(10);
            text-align: left;
            line-height: rem-calc(11);
            .size-text-title {
                display: inline-block;
                width: 100%;
                padding-right: rem-calc(13);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                .size-selected {
                    padding-left: rem-calc(1);
                }
            }
        }
        .icon-toggle-top {
            width: rem-calc(13);
            height: rem-calc(10);
            transform: rotate(0deg);
            position: absolute;
            top: rem-calc(10);
            right: rem-calc(8);
            &.icon-toggle-opened {
                transform: rotate(180deg);
            }
        }
    }
}

.wrapperModalNotifyMe {
    .tingle-modal-box {
        max-width: rem-calc(582);
        max-height: rem-calc(500);
        button.modal__closeIcon {
            width: rem-calc(14);
            height: rem-calc(14);
            right: rem-calc(20);
            top: rem-calc(13);
        }
        .notifyMeModal {
            .dark {
                font-weight: 400;
                margin-bottom: rem-calc(8);
            }
            h3 {
                font-size: rem-calc(20);
                &.notify-subtitle {
                    line-height: rem-calc(20);
                    margin-top: rem-calc(8);
                }
            }
            .modalTitle {
                display: inline-block;
            }
            .notifyBody {
                .align-item-center {
                    align-items: center;
                }
                .paddingTop {
                    padding-top: rem-calc(20);
                }
                .paddingTopExtra {
                    padding-top: rem-calc(30);
                }
                input {
                    border: rem-calc(1) solid $light-gray;
                    margin-bottom: rem-calc(0);
                }
                a {
                    font-weight: 500;
                    color: $medium-gray;
                }
                a.wide {
                    width: 100%;
                }
                .rightButton {
                    @include breakpoint(medium up) {
                        padding-left: rem-calc(10);
                    }
                }
                .leftButton {
                    @include breakpoint(medium down) {
                        order: 2;
                        padding-top: rem-calc(15);
                    }
                    @include breakpoint(medium up) {
                        padding-right: rem-calc(10);
                    }
                    button {
                        padding-left: rem-calc(0);
                        padding-right: rem-calc(0);
                    }
                }
                .green {
                    background-color: #a1c6c2;
                    color: gray(13);
                    padding: rem-calc(8 12);
                    margin-bottom: 0;
                    font-size: rem-calc(12);
                }
                .red {
                    color: red;
                    @include breakpoint(medium up) {
                        margin-left: rem-calc(25);
                    }
                }
                .continue-shopping-link {
                    margin-bottom: rem-calc(0);
                }
            }
        }
        &__content {
            padding: 0;
            .marginTop {
                margin-top: rem-calc(20);
            }
            .button-secondary {
                color: black;
                background-color: white;
            }
            .borderTop {
                border-top: rem-calc(1) solid black;
            }
            .continueShopping {
                @include breakpoint(medium up) {
                    margin-bottom: rem-calc(16);
                }
                a {
                    margin-bottom: 0;
                    width: 100%;
                }
            }
        }
    }
}

.js-select-notify-for-mobile {
    min-height: rem-calc(225);
    svg {
        width: rem-calc(16);
        height: rem-calc(16);
        margin-top: rem-calc(10);
    }
    .notifyCloseIcon {
        float: right;
    }
    .notifyMeModal {
        @include breakpoint(medium up) {
            margin-left: rem-calc(20);
        }
        .dark {
            font-weight: 600;
            margin-bottom: rem-calc(0);
        }
        h3 {
            font-size: rem-calc(20);
            height: rem-calc(30);
        }
        .modalTitle {
            display: inline-block;
        }
        .notifyBody {
            .paddingTop {
                padding-top: rem-calc(20);
            }
            .paddingTopExtra {
                padding-top: rem-calc(30);
            }
            input {
                border: rem-calc(1) solid $light-gray;
                margin-bottom: rem-calc(0);
            }
            a {
                font-weight: 500;
                color: $medium-gray;
            }
            a.wide {
                width: 100%;
            }
            .green {
                background-color: #cce8cc;
                color: #012c03;
                padding: rem-calc(10);
            }
            .red {
                background-color: #ffc6c7;
                color: red;
                padding: rem-calc(10);
            }
            .continue-shopping-link {
                margin-bottom: rem-calc(0);
            }
            .marginTop {
                margin-top: rem-calc(20);
            }
            .size {
                display: flex;
                align-items: center;
            }
            .privacy {
                text-align: right;
            }
            .toHide {
                margin-bottom: rem-calc(20);
            }
        }
    }
}

.position-relative-section {
    position: relative;
}

.pricing-vat {
    margin-bottom: 0;
    font-size: rem-calc(10);
    color: gray;
    @include breakpoint(large up) {
        line-height: rem-calc(15);
        display: block;
        margin-top: rem-calc(8);
    }
    @include breakpoint(small down) {
        margin-left: rem-calc(22);
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(15);
    }
}

.productVideoContaner {
    position: relative;
    .playVideoIcon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 0.8;
        height: rem-calc(44);
    }
}

.custom-pdp-dynamic-content {
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: rem-calc(10);
    @include breakpoint(small down) {
        font-size: rem-calc(9);
    }
    color: $white;
    background-color: #8f9dcc;
    text-align: center;
    .custom-element-root {
        width: inherit;
        & * {
            padding: rem-calc(4.5) 0;
            margin: 0;
            font-size: inherit;
            line-height: 1;
            font-weight: bold;
        }
    }
}

.ww-description-title {
    margin-top: rem-calc(27);
}

.ww-title {
    font-size: rem-calc(16);
    font-weight: 500;
    margin-bottom: rem-calc(8);
}

.ww-text {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(23);
    @include breakpoint(small down) {
        margin-bottom: rem-calc(20);
    }
}

.ww-tab-content {
    h6 {
        font-size: rem-calc(14);
        font-weight: 400;
    }
}

.ww-description-container {
    @include breakpoint(small down) {
        padding: rem-calc(0 16.5);
    }
}

.klarna-pdp-container {
    display: flex;
    @include breakpoint(small down) {
        padding-left: rem-calc(16.5);
    }
    .container {
        padding: 0;
    }
}

.drop-down-size-selector {
    width: 24.1939971vw;
    display: grid;
    &.bonus {
        width: 100%;
        .data-container {
            width: 100%;
            height: 100%;
            position: relative;
            margin: 0;
            padding: 0;
            font-size: rem-calc(11);
            .data-container-text {
                padding-left: rem-calc(11);
                padding-right: rem-calc(24);
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .size-display {
                font-size: rem-calc(14);
                font-weight: 600;
                line-height: rem-calc(20);
                display: flex;
                &.bonus-size {
                    display: unset;
                }
            }
        }
        .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(16);
            svg {
                max-height: rem-calc(7);
            }
        }
    }

    &.pointer-events-none {
        pointer-events: none;
    }
}

.js-size-panel {
    background: $white;
    z-index: 1;
}

.size-dropdown-elem {
    :active {
        background-color: rgba(241, 241, 242, 1);
    }
    .bell {
        right: 0;
        top: 25%;
        position: absolute;
        margin-right: rem-calc(16);
        @include breakpoint(small down) {
            top: 0.2rem;
        }
        @include breakpoint(large up) {
            position: static;
            opacity: 0.3;
        }
    }
    .select-label {
        @include breakpoint(medium up) {
            font-size: rem-calc(14);
            display: flex;
            align-items: center;
        }
        @include breakpoint(large up) {
            justify-content: center;
        }
        .notify-me-text {
            display: none;
        }
        .alertNotify {
            display: none;
        }
        &.notifyMe {
            .size-value {
                @include breakpoint(large up) {
                    margin-right: rem-calc(10);
                    text-decoration: line-through;
                }
            }
            .notify-me-text {
                display: inline;
                font-size: rem-calc(14);
                font-weight: 600;
                @include breakpoint(large up) {
                    margin-right: rem-calc(7);
                    font-weight: 400;
                }
            }
            .alertNotify {
                display: block;
            }
        }
        &:hover {
            @include breakpoint(medium up) {
                font-weight: 600;
            }
        }
    }
    .size-wrapper {
        font-size: rem-calc(18);
        @include breakpoint(medium up) {
            font-size: rem-calc(14);
        }
        .size-value {
            top: 0.3rem;
            left: 1rem;
            @include breakpoint(large up) {
                font-weight: 600;
            }
            @include breakpoint(medium up) {
                position: static;
                font-size: rem-calc(14);
            }
            @include breakpoint(small down) {
                position: static;
                font-size: rem-calc(14);
                line-height: rem-calc(12);
                font-weight: 600;
            }
        }
    }
    @include breakpoint(small down) {
        margin-bottom: 0;
    }
    width: 100%;
    height: rem-calc(8);
    display: inline-flex;
    position: relative;
    padding-right: 0;
}

.hide-radio {
    display: none;
}

.size-mobile-modal {
    .size-mobile-modal-header.container-flex-desktop-quantity-message {
        display: flex;
        justify-content: center;
        .quantitySelectorMobile {
            margin: 0;
            font-size: rem-calc(14);
            font-weight: 600;
        }
        .quantitySelectorMobile.bottom-section {
            padding-right: rem-calc(27.5);
        }
        .size-selectore {
            margin: 0;
            position: static;
            display: flex;
            align-items: center;
            justify-content: center;
            &.find-in-store-opened {
                display: none;
            }
        }
    }
}

.bonus-product-modal {
    .size-mobile-modal-content {
        padding-bottom: rem-calc(16);
    }

    .js-select-size-for-mobile {
        .size-mobile-modal-header.bottom-section.container-flex-desktop-quantity-message.quantity {
            display: none;
        }
    }
}

.select-your-size-label-mobile {
    margin: 0;
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-weight: 600;
}
.select-label,
.quick-add-select-label {
    width: 100%;
    height: 3rem;
    position: absolute;
    @include breakpoint(medium up) {
        padding: rem-calc(0 16);
    }
    @include breakpoint(small down) {
        position: relative;
        text-align: center;
        transform: translateY(-15%);
        height: 0;
    }
    &.notifyMe {
        color: $light-gray;
    }
}

.size-selectore {
    background-color: gray(4);
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium up) {
        height: rem-calc(40);
        margin-left: rem-calc(2);
    }
    @include breakpoint(small down) {
        border: 0;
    }
}

.quantityButtons {
    @include breakpoint(medium down) {
        background-color: white;
    }
}

.size-dropdown-data-wrapper {
    border: 1px solid transparent;
    background-color: gray(4);
    .data-container {
        .select-size {
            font-size: rem-calc(14);
            font-weight: 600;
        }
    }
}

.dropDownPanelBordered {
    border: rem-calc(1px) solid whitesmoke;
    width: 100%;

    .attribute {
        margin-top: 0 !important;
    }
}

.bonus-product-modal {
    .dropDownPanelBordered {
        position: absolute;
        transform: translateY(-100%);
        max-height: rem-calc(234);
        overflow-y: auto;
    }
}

.sizeDropDownLabels {
    position: relative;
    display: block;
    padding: 0;
    @include breakpoint(large up) {
        font-weight: 600;
    }
    @include breakpoint(small down) {
        padding: 0;
    }
    .specialHr {
        width: 100%;
        margin-bottom: rem-calc(16);
        margin-top: rem-calc(16);
        background-color: white;
        border-bottom: 0;
        @include breakpoint(large up) {
            margin: 0;
            background-color: whitesmoke;
            height: rem-calc(1);
        }
    }
    .boolean-field {
        @include breakpoint(small down) {
            padding: 0;
        }
    }
}
.productInfoHr.mobile {
    @include breakpoint(medium down) {
        margin-top: 0;
    }
}

.productInfoHr {
    width: 100%;
    background-color: $neutral;
    border-bottom: 0;
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);
    @include breakpoint(small down) {
        width: 100%;
    }
    &.small-top {
        margin: rem-calc(8) auto rem-calc(16) auto;
    }
    &.small-bottom {
        margin: rem-calc(16) auto rem-calc(8) auto;
    }
    &.info {
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(7);
    }
}

.productInfoHrMobile {
    width: 100%;
    background-color: rgba(241, 241, 242, 1);
    border-bottom: 0;
    &.info {
        margin-bottom: rem-calc(7);
    }
}

.accordianWrapper {
    width: 100%;
}

.data-a {
    display: table;
    height: rem-calc(40);
    .data-container {
        display: flex;
        align-items: center;
    }
}
.page[data-action='Product-Show'] {
    .action-bar {
        .arrow {
            position: absolute;
            right: rem-calc(16);
        }
        .data-container {
            .size-display {
                position: relative;
                left: rem-calc(16);
                top: unset;
            }
        }
    }
}

.product-color-sales {
    margin-bottom: rem-calc(16);
    @include breakpoint(medium down) {
        margin-bottom: rem-calc(12);
    }
    .colorText {
        .color-info {
            font-weight: 400;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            padding-left: rem-calc(8);
        }
    }
}

.colorText {
    font-weight: 600;
    font-size: rem-calc(16);
    line-height: rem-calc(22);
}

.product-detail {
    .cart-and-ipay .add-to-cart {
        display: block;
        padding-bottom: rem-calc(8);
        padding-top: rem-calc(9);
        margin-bottom: rem-calc(16);
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(19);
        @include breakpoint(small down) {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    .soldoutBox,
    .find-in-store-btn .soldout-search-text,
    .cart-and-ipay .add-to-cart-notify {
        display: none;
    }

    &.is-soldout {
        .cart-and-ipay .add-to-cart[data-with-findinstore='true'],
        .find-in-store-btn .regular-search-text,
        .cart-and-ipay .find-in-store-btn > i {
            display: none;
        }
        .find-in-store-btn .soldout-search-text {
            display: inline;
        }
        .soldoutBox {
            display: flex;
        }
        @include breakpoint(medium down) {
            .cart-and-ipay .add-to-cart {
                display: none;
            }
            .cart-and-ipay .add-to-cart-notify {
                display: block;
            }
        }
    }
}

.stickyHeaderPDP {
    position: fixed;
    top: 0;
    height: rem-calc(72);
    /* padding: rem-calc(16 40); */
    background: white;
    color: black;
    z-index: 1003;
    box-shadow: 0 0 rem-calc(9) 0 $offcanvas-inner-shadow-color;
    width: 100%;
    align-items: center;

    > .cell {
        width: auto;
        text-align: center;
        min-height: 72px;
        align-items: center;
        display: flex;
        border-left: 1px solid $neutral;
        /* padding: rem-calc(0 35.71); */
        flex-grow: 1;
        justify-content: center;
    }

    > .cell:first-child {
        padding: 0;
    }

    > .cell:last-child {
        padding-right: 0;
    }

    .add-to-cart {
        margin-bottom: 0;
        min-width: rem-calc(300);
    }

    .mini-image {
        width: rem-calc(56);
        height: rem-calc(56);
        object-fit: cover;
        border-radius: 50%;
        margin-right: rem-calc(35.71);
        margin-left: rem-calc(40);
    }
    .color-variation {
        width: rem-calc(18);
        height: rem-calc(18);
        object-fit: cover;
        border-radius: 50%;
        margin-right: rem-calc(8);
        outline: rem-calc(2) solid gray(5);
        outline-offset: rem-calc(2);
    }
    .stickyBannerColorWrapper {
        span {
            margin-bottom: 0;
            padding-left: rem-calc(16);
        }
    }
    .sticky-banner-price {
        font-weight: 600;
        margin: rem-calc(8);
    }
    .stickyHeaderPDP-wrapper {
        align-items: center;

        .sticky-header-pdp-product-name {
            @media screen and (min-width: 1022px) and (max-width: 1367px) {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 30%;
                white-space: nowrap;
            }
        }
    }

    .stickyBannerColorText {
        @media screen and (min-width: 1022px) and (max-width: 1367px) {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 30%;
            white-space: nowrap;
        }
    }

    .sizeDisplayStickyBanner {
        @media screen and (min-width: 1022px) and (max-width: 1367px) {
            padding: rem-calc(0 16);
        }
    }
}

.js-name {
    color: $promo;
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: 600;
    text-transform: uppercase;
    &.content-promo {
        display: flex;
        color: black;
        font-size: rem-calc(40);
        font-weight: 400;
        line-height: rem-calc(35);
        text-transform: capitalize;
        margin-bottom: rem-calc(24);
        margin-top: rem-calc(8);
        padding-right: rem-calc(8);
        font-family: 'Spezia', Helvetica, Arial, sans-serif;
    }
    &.content-promo-mobile {
        color: black;
        display: flex;
        font-size: rem-calc(20);
        font-weight: 400;
        line-height: rem-calc(26);
        text-transform: capitalize;
        margin-top: rem-calc(8);
        margin-bottom: rem-calc(16);
        font-family: 'Spezia', Helvetica, Arial, sans-serif;
    }
}
.complete-your-promo-description-mobile {
    margin-bottom: rem-calc(16);
}
.js-promo-more-info {
    cursor: pointer;
    border-bottom: 1px solid;
    font-weight: 600;

    @media screen and (min-width: 1366px) {
        position: absolute;
        bottom: rem-calc(24);
    }

    &:hover {
        color: $dark-gray;
    }
}

.promo-description-content {
    @include breakpoint(large up) {
        margin-bottom: rem-calc(35);
    }
    @media screen and (min-width: 1022px) and (max-width: 1365px) {
        margin-bottom: 0;
    }
}

.getRect {
    height: rem-calc(52);
    width: rem-calc(8);
    background-color: rgba(219, 215, 214, 255);
}

.wearingModelWrapper {
    display: -webkit-box;
    padding-top: 1rem;
    padding-bottom: rem-calc(6);
}

.modelIsWearingText {
    padding-left: 1rem;
    padding-top: 0.5rem;
    transform: translateY(-0.3rem);
    font-size: rem-calc(12);
    line-height: rem-calc(22);
    color: #000000;
}

.on-sale-text {
    pointer-events: none;
    text-decoration: none;
    margin-left: 0;
    padding-left: 0;
    line-height: rem-calc(26);
    font-family: 'SpeziaExtended', Helvetica, Arial, sans-serif;
    font-size: rem-calc(20);
    @include breakpoint(medium down) {
        font-size: rem-calc(16);
    }
}

.promo-messages {
    @include breakpoint(medium up) {
        margin-left: rem-calc(180);
    }
}

.stickyBannerColorPreview {
    transform: scale(0.8);
    outline: rem-calc(2) solid black;
    outline-offset: rem-calc(5);
}

.tingle-demo.productInfo {
    .modalGetProductInfo {
        .productInfoHr {
            margin-top: rem-calc(40);
            margin-bottom: rem-calc(25);
        }
    }
}

.promo-background-modal {
    backdrop-filter: blur(80px);
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    /* For Google Chrome */
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    /* For Internet Explorer */
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}
.page[data-action='Product-Show'] {
    .recommendation-full-wrapper {
        .recommendation-carousel {
            &__cards {
                .tile-colors {
                    display: none;
                }
            }
        }
    }
}
.page[data-action='Cart-Show'] {
    .recommendation-full-wrapper {
        .grid-container {
            &-header {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
.recommendation-full-wrapper,
.cm-product-filmstrip {
    overflow: hidden;
    margin-bottom: rem-calc(80);
    .cross-sell-promo-wrap {
        margin-top: rem-calc(24);
    }
    &.background-green {
        @include breakpoint(medium down) {
            background: $background-promo;
        }
        @media screen and (min-width: 1022px) and (max-width: 1365px) {
            background: $background-promo;
        }
        .top {
            margin-bottom: rem-calc(24);
            min-height: rem-calc(20);
        }
        .box-carousel-root {
            .completeThePromoContainer {
                @include breakpoint(medium down) {
                    margin-left: rem-calc(-8);
                }
            }
        }
    }
    &.cross-sell-wrapper {
        margin-bottom: rem-calc(40);
        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
        @include breakpoint(medium up) {
            margin-top: rem-calc(69.5);
        }
        .no-padding-right {
            @include breakpoint(medium up) {
                padding-right: 0;
            }
        }
    }
    padding: 0;
    .grid-container,
    .cm-grid-container {
        &-slider {
            max-width: none;
            padding-right: 0;
            padding-left: rem-calc(40);
            @include breakpoint(medium down) {
                padding-left: rem-calc(16);
            }
        }
        &-header {
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
            @include breakpoint(medium down) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }
            &.no-padding-right {
                @include breakpoint(medium up) {
                    padding-right: 0;
                }
            }
        }
    }
    .top,
    .cm-top {
        margin-bottom: rem-calc(40);
        position: relative;
        top: unset;
        .title,
        .cm-title {
            font-size: rem-calc(40);
            line-height: rem-calc(35);
            font-weight: 400;
            margin-bottom: 0;
            @include breakpoint(medium down) {
                font-size: rem-calc(20);
                line-height: rem-calc(26);
            }
        }
        .arrows,
        .cm-arrows {
            &__wrapper {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                @include breakpoint(medium down) {
                    display: none;
                }
                &.complete-tile-carousel {
                    padding-right: rem-calc(40);
                }
            }
            &__left,
            &__right {
                display: inline-block;
                &.active {
                    cursor: pointer;
                }
                &:not(.active) {
                    opacity: 0.5;
                }
                svg {
                    max-width: 20px;
                }
            }
            &__left {
                padding-right: 5px;
            }
            &__right {
                padding-left: 5px;
            }
        }
    }
    .cross-sell-promo-wrapper {
        @include breakpoint(large up) {
            display: none;
        }
        @media screen and (min-width: 1022px) and (max-width: 1365px) {
            display: block;
        }
        .cross-sell-promo-label {
            font-size: rem-calc(12);
            font-weight: 600;
            line-height: rem-calc(20);
        }
    }

    .complete-your-promo-description {
        @media screen and (min-width: 1022px) and (max-width: 1365px) {
            display: none;
        }
    }
    .recommendation-carousel,
    .cm-product-filmstrip-carousel {
        &__cards {
            display: flex;
            margin-left: -4px;
            margin-right: -4px;
            width: calc(100% + 3px);
            transform: translateX(3px);
            overflow-x: auto;
            position: relative;
            user-select: none;
            @include scrollbars(2px, $dark-gray, $light-gray);
            &.smooth {
                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;
            }
            > div {
                margin-left: 4px;
                margin-right: 4px;
                flex: none;
                max-width: 18.96046852122987vw;
                padding-bottom: 0;
                position: relative;
                @include breakpoint(medium down) {
                    max-width: 234px;
                }
                &:first-of-type {
                    margin-left: 0;
                }
                .productDescription {
                    @include breakpoint(medium down) {
                        margin-bottom: rem-calc(18);
                    }
                    &-name {
                        margin-bottom: rem-calc(8);
                        .js-pdp-link {
                            white-space: nowrap;
                            display: inline-block;
                            width: calc(18.96046852122987vw - 40px);
                            @include breakpoint(medium down) {
                                width: rem-calc(200);
                            }
                        }
                    }
                    &-priceRow {
                        margin-bottom: 0;
                        .span-price-row-plp {
                            display: inline-block;
                            .sales.first-span {
                                order: 0;
                                .value {
                                    color: $black;
                                }
                            }
                            .strike-through.list {
                                order: 1;
                                color: $light-gray;
                                padding: 0;
                                margin: rem-calc(0 8);
                                &:hover {
                                    text-decoration: line-through;
                                }
                            }
                            .sales.percentage {
                                order: 2;
                            }
                        }
                    }
                }
                .promotion-info-plp {
                    margin-bottom: 0;
                }
                .product-tile__promo-message {
                    margin-top: rem-calc(8);
                }
                img {
                    width: 100%;
                }
            }
            .double-currency-sales {
                line-height: rem-calc(22);
                font-size: rem-calc(12);
                font-weight: 400;
            }
            .double-currency-list.strike-through {
                font-size: rem-calc(12);
            }
            .price .strike-through ~ .sales {
                @include breakpoint(medium down) {
                    font-size: rem-calc(14);
                }
            }
            .price {
                .strike-through.list {
                    font-size: rem-calc(14);
                    .value {
                        font-size: rem-calc(14);
                    }
                }
            }
        }
    }
}

.authAddition {
    margin-top: rem-calc(15);
    a {
        text-decoration: underline;
    }
}

.attribute.variation-attribute-size {
    width: inherit;
}
.cell.action-bar.drop-down-size-selector.variation-attribute-size {
    @include breakpoint(xlarge down) {
        flex: 2 10%;
    }
    flex: 2;
}
.arrow.arrow-action-close.variation-attribute-size,
.arrow.arrow-action-open.variation-attribute-size {
    @include breakpoint(xlarge down) {
        right: rem-calc(30);
    }
}
.size-dropdown-data-wrapper.js-panel-opener.data-a {
    width: inherit;
}
.cell.large-auto.attribute-container.container-flex-desktop-quantity-message.size-selectore.quantity {
    display: flex;
    justify-content: center;
}

.cc-mandatory-fields-label{
    margin-top: rem-calc(8);
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: rem-calc(12);
    font-style: normal;
    font-weight: 400;
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.48);
}
