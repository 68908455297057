@import "../../mixins/layouts";

$include-fonts: false;
@import "../../setup/settings";
@import "../../mixins/helpers";
@import "../../plugins/slick";

.ratings-reviews-section {
    padding-top: rem-calc(60);
    [class^=tt-c-], [class^=tt-l-], [class^=tt-o-], [class^=tt-u-], [class^=tt-w-] {
        font-family: 'Spezia',Helvetica,Arial,sans-serif;
         @include breakpoint (medium up){
            order: 2;
         }
    }
    .tt-l-grid__row.tt-c-review__grid-row{
        justify-content: space-between;
    }
    #ratingsReviewsTitle {
        text-align: center;
        .title {
            font-weight: normal;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            margin-bottom: rem-calc(18);
        }
    }
    .tt-o-byline__item.tt-o-byline__author, .tt-o-badge {
        text-transform: none;
        letter-spacing: rem-calc(0.2);
        font-size: rem-calc(16);
        line-height: rem-calc(22);
        color: #262626;
    }
    .tt-o-badge {
        display: block;
        background-color: initial;
        padding: 0;
        margin: 0;
        font-size: rem-calc(10);
        line-height: rem-calc(18);
        &.tt-o-badge--staff {
            display: none;
        }
    }
    .tt-c-review {
        padding: 0;
        border-bottom: 0;
        &:last-of-type {
            border-bottom: 0;
            .tt-l-grid.tt-c-review__content{
                padding-bottom: 0;
            }
        }

        .tt-u-mb--md {
            margin-bottom: rem-calc(10);
        }
    }
    .tt-c-reviews-summary__no-reivews {
        display: none;
    }
    .tt-c-ratings-breakdown__table, .tt-c-summary-dim, .tt-c-reviews-summary__write-review-wrap {
        display: none;
    }
    .tt-c-reviews-summary__content {
        background-color: #F6F6F5;
        padding: rem-calc(40) 0;
        > *:first-child {
            display: none!important;
        }
        > :last-child {
            display: none!important;
        }
        [class^=tt-c-], [class^=tt-l-], [class^=tt-o-], [class^=tt-u-], [class^=tt-w-] {
             @include breakpoint (medium up){
                order: 1;
             }
        }
    }
    .tt-o-listbox__list {
        background-color: #FFFFFF;
        margin-left: rem-calc(0);
        margin-top: rem-calc(1);
        width: rem-calc(210);
        box-shadow: rem-calc(0 3 6) #00000029;
        .tt-o-listbox__option {
            text-align: left;
            position: relative;
            line-height: rem-calc(8);
            padding: rem-calc(8 16);
            outline: none;
            display: flex;
            align-items: flex-end;
            &:first-child {
                padding-top: rem-calc(16);
            }
            &:last-child {
                padding-bottom: rem-calc(16);
            }
            .tt-o-listbox__option-icon-wrap {
                width: rem-calc(16);
                height: rem-calc(16);
                border: rem-calc(1) solid $black;
                margin-right: rem-calc(8);
                position: relative;
                min-width: 0;
                .tt-o-icon {
                    display: none;
                }
            }
            .tt-o-listbox__option-text {
                font-size: rem-calc(12);
            }
            &--is-active {
                outline: initial;
            }
            &[aria-selected=true] {
                .tt-o-listbox__option-text {
                    font-weight: bold;
                }
                .tt-o-listbox__option-icon-wrap {
                    &::after {
                        background: $black;
                        content: '';
                        position: absolute;
                        height: rem-calc(8);
                        width: rem-calc(8);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                outline: initial;
            }
            .tt-o-icon.tt-o-icon--checkbox--off.tt-o-icon--md.tt-o-listbox__option-icon.tt-o-listbox__option-icon {
                display: none;
            }
            .tt-o-icon.tt-o-icon--checkbox--on.tt-o-icon--md.tt-o-listbox__option-icon.tt-o-listbox__option-icon {
                display: none;
            }
        }
    }
    .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--2 {
        width: 100%;
        margin-bottom: rem-calc(32);
    }
    @include breakpoint (medium down) {
        .tt-l-grid__row.tt-c-reviews-summary__grid-row {
            justify-content: center;
            text-align: center;
            display: block;
            padding: 0;
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--1.tt-c-reviews-summary__grid-col--1 {
            border-right: 0!important;
            
            .tt-c-reviews-summary__rating.tt-u-mb--sm {
                border-right: rem-calc(1) solid #000;
                margin-right: rem-calc(4);
                padding-right: rem-calc(4);
            }
            .tt-c-ratings-breakdown.tt-u-spacing--md.tt-c-reviews-summary__ratings-breakdown {
                order: 2;
                .tt-c-ratings-breakdown__heading.tt-u-align-center {
                    white-space: nowrap;
                }
            }
        }
        .tt-c-ratings-breakdown__heading.tt-u-align-center {
            line-height: rem-calc(12)!important;
        }
    }
    @include breakpoint (small down) {
        .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--3 {
            order: 1;
            width: 100%;
            margin-bottom: 0;
            .tt-c-dimension__heading.tt-u-mb--sm {
                @include breakpoint (medium down){
                    margin-bottom: 0;
                    margin-top: rem-calc(8);
                }
                .tt-c-dimension__value-label {
                    font-size: rem-calc(14);
                    letter-spacing: rem-calc(0.28);
                    color: #000;
                }
            }
        }
        .tt-c-rating.tt-c-review__rating.tt-u-mb--sm {
            order: 2;
            width: 100%;
            margin: rem-calc(21 0);
        }
        .tt-c-review__date.tt-u-mb--sm {
            order: 6!important;
            margin: rem-calc(0 0 10 0);
        }
        .tt-o-byline.tt-u-spacing--left--xs.tt-c-review__byline.tt-u-mb--lg {
            order: 1;
            margin-bottom: 0;
        }
        .tt-c-review__heading.tt-u-mb--sm {
            order: 3;
        }
        .tt-c-review__text {
            order: 4;
            margin-bottom: rem-calc(32);
            width: 100%;
            margin-bottom: rem-calc(16);
            .tt-u-mb--md {
                margin-bottom: 0;
            }
        }
        .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--2 {
            margin-bottom: rem-calc(40);
            order: 6;
            &:last-child {
                margin-bottom: rem-calc(32);
            }
        }
        .tt-c-review__expander.tt-o-expander.tt-o-expander--with-dashed-border {
            width: 100%;
            order: 5;
        }
        .tt-l-grid__row.tt-c-review__grid-row {
            .tt-l-grid__footer.tt-c-review__grid-footer {
                .tt-c-review-toolbar.tt-u-mb--xs {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-bottom: 0;
                    .tt-c-review-toolbar__content--left.tt-u-spacing--left--md {
                        .tt-o-button.tt-o-button--toolbar.tt-o-button--flat-icon-left.tt-c-review-toolbar__vote.tt-c-review-toolbar__vote:first-of-type {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .tt-l-grid__row.tt-c-reviews-summary__grid-row {
            justify-content: center;
            text-align: center;
            display: block;
            padding: 0;
            line-height: rem-calc(14);
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--1.tt-c-reviews-summary__grid-col--1 {
            .tt-c-reviews-summary__rating.tt-u-mb--sm {
                border-right: 1px solid #000;
                margin-right: rem-calc(4);
                padding-right: rem-calc(4);
            }
            .tt-c-ratings-breakdown.tt-u-spacing--md.tt-c-reviews-summary__ratings-breakdown {
                order: 2;
                .tt-c-ratings-breakdown__heading.tt-u-align-center {
                    white-space: nowrap;
                }
            }
        }
        .tt-c-ratings-breakdown__heading.tt-u-align-center {
            line-height: rem-calc(12)!important;
        }
        .tt-l-grid__header.tt-c-review__grid-header {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .reviewFilterBtn {
        .tt-o-listbox__list {
            margin-left: 0;
            margin-top: 0;
            &--has-selection .tt-o-listbox__option-icon-wrap {
                min-width: 0;
            }
            .tt-o-listbox__option {
                padding-left: rem-calc(24);
                .tt-o-listbox__option-text {
                    font-size: rem-calc(12);
                }
                .tt-o-listbox__rating-option {
                    position: relative;
                    bottom: rem-calc(1);
                }
            }
        }
    }
    .tt-c-reviews-summary__rating-number, .tt-c-reviews-summary__rating-text, .tt-c-ratings-breakdown__heading {
        font: normal normal normal 14px/12px "Brandon Grotesque",Helvetica,Arial,sans-serif;
        margin-right: 0;
    }
    .tt-l-grid__row.tt-c-reviews-summary__grid-row {
        justify-content: center;
        text-align: center;
        display: block;
        padding: 0;
        line-height: rem-calc(14);
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--1.tt-c-reviews-summary__grid-col--1 {
            width: min-content!important;
            margin: 0!important;
            display: inline-flex;
            .tt-c-reviews-summary__rating.tt-u-mb--sm {
                display: inline;
                order: 2;
                white-space: nowrap;
                margin-bottom: 0;
                line-height: rem-calc(12);
                @include breakpoint (medium up) {
                    border-right: rem-calc(1) solid #000;
                    padding-right: rem-calc(4);
                }
            }
            .tt-c-rating.tt-c-reviews-summary__stars.tt-u-mb--lg {
                margin-bottom: 0;
                align-items: center;
                padding-right: rem-calc(6.5);
            }
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--2.tt-c-reviews-summary__grid-col--2 {
            width: min-content!important;
            margin: 0!important;
            display: inline-block;
            padding-left: rem-calc(4);
            vertical-align: top;
            line-height: rem-calc(14);
            .tt-c-ratings-breakdown__heading.tt-u-align-center {
                white-space: nowrap;
            }
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--3.tt-c-reviews-summary__grid-col--3 {
            width: min-content!important;
            margin: 0!important;
            flex: 0!important;
        }
        .tt-l-grid__col.tt-c-reviews-summary__grid-col.tt-l-grid__col--4.tt-c-reviews-summary__grid-col--4 {
            width: min-content!important;
            margin: 0!important;
            flex: 0!important;
        }
        .tt-c-ratings-breakdown.tt-u-spacing--md.tt-c-reviews-summary__ratings-breakdown {
            order: 2;
            padding-left: rem-calc(4);
        }
        .tt-c-ratings-breakdown__heading.tt-u-align-center {
            text-align: left;
            line-height: rem-calc(14);
            white-space: nowrap;
        }
        .tt-c-ratings-breakdown {
            margin-bottom: 0;
        }
    }
    .tt-c-rating__star {
        width: rem-calc(14);
        height: rem-calc(14);
        .tt-o-icon {
            width: 100%;
            height: 100%;
        }
    }
    .tt-c-reviews-list__header {
        display: none;
    }
    .tt-c-review__heading-text {
        font-weight: bold;
        font-size: rem-calc(14);
        @include breakpoint (medium up){
            order: 3;
        }
    }
    .tt-c-review__heading.tt-u-mb--sm {
        margin-bottom: rem-calc(8);
        margin-top: rem-calc(0);
    }
    .tt-c-review__text-content {
        font-weight: normal;
        color: #262626;
        font-size: rem-calc(14);
    }
    .tt-o-search-field.tt-o-search-field--with-search-icon.tt-c-reviews-toolbar__search {
        display: none;
    }
    .tt-w-reviews-list {
        .tt-c-reviews-list__content {
            margin: 0 auto;
            padding-top: rem-calc(16);
        }
    }
    .tt-c-product-about.tt-c-review__product.tt-u-mb--md {
        display: none;
    }
    .tt-l-grid.tt-c-review__content {
        position: relative;
        padding-bottom: rem-calc(56);
        @include breakpoint (medium down){
            padding-bottom: rem-calc(34);
        }
    }
    .tt-c-review__date.tt-u-mb--sm {
        width: 100%;
        bottom: 0;
        font-size: rem-calc(14);
        font-weight: normal;
        letter-spacing: 0;
        color: #262626;
        margin-bottom: rem-calc(16);
        @include breakpoint (medium down){
            margin-bottom: rem-calc(34);
        }
    }
    .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--1.tt-c-review__grid-col--1 {
        width: 9.45vw!important;
        margin-right: 0!important;
        .tt-c-review__heading.tt-u-mb--sm {
            width: 100%;
        }
        .tt-o-byline.tt-u-spacing--left--xs.tt-c-review__byline.tt-u-mb--lg {
            margin-bottom: rem-calc(8);
        }
        .tt-c-dimension.tt-u-mb--xl.tt-c-dimension--3 {
            width: 100%;
            .tt-c-dimension__heading.tt-u-mb--sm {
                .tt-c-dimension__value-label {
                    font-size: rem-calc(14);
                    letter-spacing: rem-calc(0.28);
                    color: #000;
                }
                .tt-c-dimension__value-label{
                    font-weight: 400;
                }
            }
        }
        .tt-c-review__heading.tt-u-mb--sm {
            .tt-c-review__heading.tt-u-mb--sm {
                font-weight: normal;
                font-family: 'Spezia',Helvetica,Arial,sans-serif;
                line-height: rem-calc(22);
                font-size: rem-calc(16);
                letter-spacing: rem-calc(0.23);
                color: #000000;
                opacity: 1;
            }
        }
    }
    .tt-c-dimension {
        .tt-c-dimension__heading.tt-u-mb--sm {
            margin-bottom: rem-calc(16);
            .tt-c-dimension__label, .tt-c-dimension__value-label {
                color: #000;
                font-size: rem-calc(14);
                line-height: rem-calc(22);
                letter-spacing: rem-calc(0.2);
            }
            .tt-c-dimension__value-label{
                font-weight: 600;
            }
        }
        .tt-c-dimension__bar-wrap.tt-c-dimension__bar-wrap--range {
            .tt-c-dimension__bar {
                order: 1;
                height: rem-calc(14);
                background: #fff 0% 0% no-repeat padding-box;
                .tt-c-dimension__dot {
                    width: rem-calc(13);
                    height: rem-calc(13);
                }
                &::after {
                    left: 0;
                    height: rem-calc(2);
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    background-color: #BEBEBE;
                }
                &:first-child {
                    margin-bottom: rem-calc(8);
                }
            }
            .size-label-container {
                display: flex;
                flex-wrap: nowrap;
                gap: rem-calc(8);
                width: 100%;
                order: 1;
                .label-wrapper {
                    width: 50%;
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    .rr-dimension-label {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: rem-calc(16);
                    }
                    .label-too-small {
                        text-align: left;
                    }
                    .label-too-large {
                        text-align: right;
                    }
                }
            }
            .rr-dimension-label {
                display: block;
            }
        }
    }
    .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--2.tt-c-review__grid-col--2 {
        @include breakpoint (medium up){
            margin: 0!important;
            width: 46.78vw!important;
            display: flex;
            flex-direction: column;
        }
        .tt-c-rating {
            margin: 0;
            margin-bottom: rem-calc(21);
        }
    }
    .tt-c-rating.tt-c-review__rating.tt-u-mb--sm {
        @include breakpoint (medium up) {
            order: 1;
            margin-bottom: 0;
        }
    }
    .tt-c-review__purchased.tt-u-mb--sm {
        display: none;
    }
    .tt-l-grid__col.tt-c-review__grid-col.tt-l-grid__col--3.tt-c-review__grid-col--3 {
        margin: 0!important;
        width: 18.3vw!important;
        .tt-c-review__purchased.tt-u-mb--sm {
            display: none;
        }
    }
            .tt-c-review-toolbar.tt-u-mb--xs {
                .tt-c-review-toolbar__content--left.tt-u-spacing--left--md {
                    .tt-o-button.tt-o-button--toolbar.tt-o-button--flat-icon-left.tt-c-review-toolbar__vote.tt-c-review-toolbar__vote:first-of-type {
                        margin-left: 0;
                    }
                }
            }
    .tt-c-review-toolbar.tt-u-mb--xs {
        .tt-c-review-toolbar__content--left.tt-u-spacing--left--md {
            .tt-c-review-toolbar__text {
                display: none;
            }
        }
        .tt-c-review-toolbar__content--right {
            .tt-c-review-toolbar__actions.tt-u-spacing--left--lg {
                display: none
            }
        }
    }
    .tt-o-pagination {
        padding: rem-calc(30 0 0 0);
        margin-bottom: rem-calc(80);
        border-bottom: rem-calc(1) solid rgba(0,0,0,.12);
        .tt-o-button.tt-o-button--icon {
            display: none;
        }
        .tt-o-page-list {
            padding: 0;
            .tt-o-page-list__number {
                color: #000;
                padding: rem-calc(0 15 0 15);
                margin: 0;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                &.tt-is-active {
                    color: #000;
                    .tt-o-page-list__link {
                        font-weight: bold;
                        background-color: initial;
                        border-radius: initial;
                        color: #000;
                    }
                }
                .tt-o-page-list__link {
                    letter-spacing: rem-calc(0.9);
                    font-size: rem-calc(15);
                    width: initial;
                    height: initial;
                    line-height: rem-calc(22);
                }
            }
        }
    }
    .rr-dimension-label {
        display: none;
        font-size: rem-calc(10);
    }
}
.tt-c-reviews-list-utilities {
    @include flexGenerator(row, flex-start, center);
    margin: 0;
    @include fixHeight(rem-calc(50));
    width: 100%;
    border-bottom: rem-calc(1) solid #DDDDDD;
    @include breakpoint (medium down) {
        padding-left: rem-calc(0);
        padding-right: rem-calc(0);
    }
    &.hidden {
        display: none!important;
    }
    &-filter {
        &:hover {
            cursor: pointer;
            background-color: #F6F6F5;
        }
        .filterBtn {
            @include fixHeight(rem-calc(50));
            padding: rem-calc(16 18);
            color: currentColor;
        }
    }
    .filterMenu-header {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: rem-calc(16);
        font-size: rem-calc(12);
        > :first-child {
            margin-right: rem-calc(8);
        }
        .icon {
            .small {
                width: rem-calc(10);
                height: rem-calc(6);
            }
        }
        &:hover {
            cursor: pointer;
        }
    }
    .tt-c-reviews-applied-filters {
        display: none;
    }
    .tt-c-reviews-toolbar__sort {
        height: initial;
        margin-bottom: initial;
        order: 1;
    }
    .tt-c-reviews-toolbar .tt-o-listbox.tt-c-reviews-toolbar__sort .tt-o-listbox__btn.tt-o-listbox__btn--with-label {
        .tt-o-listbox__btn-label {
            display: none;
        }
    }
    .tt-c-reviews-toolbar {
        margin-bottom: 0;
        order: 2;
        height: 100%;
        flex-direction: row;
        .tt-c-review-filters {
            margin-bottom: 0;
            height: 100%;
            .tt-o-listbox {
                max-height: initial;
                .tt-o-listbox__btn.tt-o-listbox__btn--with-label {
                    .tt-o-listbox__btn-label {
                        display: none;
                    }
                }
            }
        }
        .tt-c-reviews-toolbar__sort {
            .tt-o-listbox__list {
                .tt-o-listbox__option {
                    padding: rem-calc(5 16);
                    line-height: rem-calc(20);
                    &:first-child {
                        padding-top: rem-calc(14);
                    }
                    &:last-child {
                        padding-bottom: rem-calc(14);
                    }
                }
            }
        }
    }
    .tt-c-reviews-filters-row {
        margin-bottom: 0;
    }
    ul.tt-o-listbox__list {
        min-width: rem-calc(220)!important;
        padding: 0!important;
        .tt-o-listbox__option {
            .tt-o-listbox__option-text {
                font-size: rem-calc(14);
                height: rem-calc(16);
            }
            .tt-o-listbox__rating-option {
                .tt-o-icon {
                    margin-right: rem-calc(6);
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
            }
        }
    }
    .tt-c-reviews-toolbar .tt-o-listbox.tt-c-reviews-toolbar__sort .tt-o-listbox__btn.tt-o-listbox__btn--with-label, .tt-c-review-filters .tt-o-listbox .tt-o-listbox__btn {
        padding: rem-calc(16);
        font-size: rem-calc(12);
        width: initial;
        min-width: initial;
        border: 0;
        max-height: initial;
        height: 100%;
        padding-left: 0;
        .tt-o-icon.tt-o-icon--caret-down {
            width: rem-calc(11);
            height: rem-calc(9);
            &.tt-o-listbox__btn-icon--open {
                top: 40%;
            }
        }
        &[aria-expanded=true] {
            .tt-o-listbox__label, .tt-o-listbox__btn-label {
                font-weight: bold;
            }
        }
        > :first-child {
            margin-right: rem-calc(7);
        }
        .tt-o-listbox__label, .tt-o-listbox__btn-label {
            font-size: rem-calc(12);
            text-transform: uppercase;
            margin-right: rem-calc(10);
            white-space: nowrap;
        }
        &:hover {
            cursor: pointer;
            font-weight: bold;
        }
        .icon {
            .small {
                width: rem-calc(10);
                height: rem-calc(6);
            }
        }
    }
    .tt-c-review-filters {
        .tt-o-listbox {
            margin-bottom: 0;
        }
    }
    .btn {
        position: relative;
        height: 100%;
        .icon {
            max-width: rem-calc(20);
            max-height: rem-calc(20);
            &.filter {
                padding: rem-calc(3.5 2.5);
                box-sizing: border-box;
            }
            &.arrow {
                padding: rem-calc(2 0);
            }
        }
        .text {
            font-size: rem-calc(12);
            letter-spacing: 0.2px;
            line-height: rem-calc(20);
        }
        .under {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 14px;
            padding-bottom: rem-calc(3);
            border-bottom: 1px solid #000000;
            text-transform: capitalize;
        }
    }
    .circleNumberLabel {
        border-radius: 50%;
        border: 1px solid #C75015;
        color: #C75015;
        display: inline-block;
        font-size: rem-calc(11);
        height: rem-calc(20);
        letter-spacing: 0.2px;
        line-height: rem-calc(18);
        margin-left: rem-calc(7);
        text-align: center;
        vertical-align: top;
        width: rem-calc(20);
    }
    .grid-mode-toggle a {
        color: #CCCCCC;
        text-decoration: none;
        transition: color 0.25s ease;
        &:hover,
        &.active {
            color: #000000;
        }
        &+a {
            margin-left: rem-calc(10);
        }
    }
}

@import "../plp/additionalfilters";

.review-summary-pdp {
    display: none;
    margin-bottom: 0;
    margin-top: rem-calc(20);
    width: min-content;
    &.loaded {
        display: flex;
        align-items: center;
    }
    @include breakpoint (medium down) {
        padding: rem-calc(0 14);
    }
    &:hover {
        cursor: pointer;
        .total-reviews {
            text-decoration: underline;
        }
    }
    .tt-c-rating {
        margin-bottom: 0;
        .tt-c-rating__star {
            width: rem-calc(14);
            height: rem-calc(14);
            .tt-o-icon {
                width: 100%;
                height: 100%;
            }
        }
    }
    .total-reviews {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        letter-spacing: rem-calc(0.2);
        color: #000;
        margin-bottom: 0;
        margin-left: rem-calc(12);
        width: max-content;
    }
    &-mobile {
        margin: rem-calc(0 40 30 40);
        @include breakpoint (medium down) {
            margin: rem-calc(0 20 30 20);
        }
    }
}


.tt-c-review-responses.tt-u-mb--lg {
    margin: rem-calc(14.5 0 0 0);

    @include breakpoint(small down) {
        margin: rem-calc(-1 0 0 0);
        order: 7;
    }
        
}

.tt-c-review-responses__item {
    margin-bottom: 0;
}

.tt-c-review-responses__source-wrap.tt-u-mb--lg {
    border: none;
    background-color: #F2EDED;
    padding: rem-calc(24 16);
    margin: 0;

    .tt-c-review-responses__source.tt-u-mb--sm {
        margin-bottom: rem-calc(8);
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }

    .tt-c-review-responses__responses.tt-u-mb--md {
        margin: 0;

        .tt-c-review-responses__item.tt-u-mb--lg {
            margin: 0;
        }

        .tt-c-review-responses__text.tt-u-mb--md {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        }

        .tt-o-byline.tt-u-spacing--left--xs.tt-c-review-responses__byline {
            .tt-o-byline__item.tt-o-byline__author {
                display: none;
            }
                    
            .tt-o-byline__item.tt-o-byline__on-date {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                margin: 0;
                color: $black;
            }
        }
    }
}

