
.flcActiveFilter {
    @include flexGenerator(row, space-between, center);
    background-color: #F6F6F5;
    padding: rem-calc(0 40);
    &-refinement {
        @include flexGenerator(row);
        flex-wrap: wrap;
        flex-basis: min-content;
    }
    &-active {
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.2px;
        line-height: 12px;
        margin-right: rem-calc(5);
        overflow: hidden;
        padding: rem-calc(19 0);
        white-space: nowrap;
        width: min-content;
        .icon.close {
            height: rem-calc(20);
            margin-left: rem-calc(5);
            margin-right: rem-calc(35);
            width: rem-calc(20);
        }
    }
    &-removeAll {
        border-bottom: 1px solid #000000;
        min-width: min-content;
        overflow: hidden;
        padding-bottom: rem-calc(3);
        white-space: nowrap;
        cursor: pointer;
    }
}
.flcSortContainer {
    padding: rem-calc(16 18);
    &:hover {
        cursor: pointer;
        background-color: #F6F6F5;
    }
}