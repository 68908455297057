@import "swatch";

.product {
  &-id {
    display: inline-block;
    margin-bottom: rem-calc(8);
  }
}
.modal {
  display: none;
  width: 100%;
  height: 200vh;
  background-repeat: no-repeat;
  z-index: 2000;
  position: fixed;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: zoom-out;
  overflow: hidden !important;
  background-color: white;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  position: relative;
  &.bonus-product {
    width: 100%;
  }
}

.mySlides {
  display: none;
}

.cursor {
  cursor: zoom-in;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: fixed;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: black;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
#imageSection {
  #myModal {
    @include breakpoint(large up) {
      height: fit-content;
    }
    justify-content: center;
    .prev,
    .next {
      position: absolute;
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
  }
}

/* Position the "next button" to the right */
.next {
  right: 352px;
  border-radius: 3px 0 0 3px;
}

.arrow-zoom {
  height: 32px;
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.productImageCarouselItem {
  cursor: zoom-in;
}

#crossSellContainer {
  .product-tile {
    text-align: left;
    .hover-info-bag-wishlist {
      display: none;
    }
    .pdp-link {
      font-weight: $global-weight-normal;
      line-height: 0;
      .link {
        letter-spacing: 0;
        line-height: rem-calc(20);
      }
    }

    .image-container {
      position: relative;
      overflow: hidden;
      padding-top: 140%;

      .tile-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .comingSoonBox {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: rem-calc(16);

        .force-hover {
          text-decoration: underline;
          font-weight: $global-weight-normal;
          font-size: rem-calc(12);
        }

        &:not(.hidden) {
          display: flex;
        }
      }
    }

    .price .strike-through {
      display: inline-block;
    }
    .price-container {
      margin-top: rem-calc(7);
      text-align: left !important;
      .sales {
        .value {
          font-weight: 600;
          font-size: rem-calc(14);
        }
      }
    }
  }
}

.zoom {
  display: flex;
}
