.comingSoonBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .title-container {
        align-self: center;
        background: rgba(255, 255, 255, 0.7);
        padding: rem-calc(15);
        width: 100%;
        height: rem-calc(167);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include breakpoint (medium down) {
            height: rem-calc(111);
            padding: 0;
        }
        .coming-soon-title {
            font-size: rem-calc(16);
            font-weight: 600;
            margin-bottom: rem-calc(6);
            @include breakpoint (medium down) {
                font-size: rem-calc(12);
            }
        }
        .coming-soon-link {
            text-decoration: underline;
            font-size: rem-calc(14);
            &:hover {
                text-decoration: none;
            }
            @include breakpoint (medium down) {
                font-size: rem-calc(12);
            }
        }

        h3, p {
            margin: 0;
        }
    }

    .valign {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.soldoutBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .title-container {
        align-self: center;
        background: rgba(255, 255, 255, 0.8);
        padding: rem-calc(38 15);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include breakpoint (medium down) {
            padding: rem-calc(22);
        }
        .soldout-title {
            font-size: rem-calc(16);
            letter-spacing: rem-calc(0.32);
            line-height: rem-calc(23);
            font-weight: 600;
            @include breakpoint (medium down) {
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.24);
                line-height: rem-calc(18);
            }
        }
        h3, p {
            margin: 0;
        }
    }
    &.horizontal {
        .title-container {
            @include breakpoint (medium down) {
                padding: rem-calc(31 22);
            }
        }
    }
}
